export const getContentStatus = (data) => {
  switch (data) {
    case 'Pending':
      return <span className='badge pending'>Pending</span>;
    case 'pending':
      return <span className='badge pending'>Pending</span>;
    case 'Archived':
      return <span className='badge pending'>Archived</span>;
    case 'archived':
      return <span className='badge pending'>Archived</span>;
    case 'Approved':
      return <span className='badge approved'>Approved</span>;
    case 'approved':
      return <span className='badge approved'>Approved</span>;
    case 'Declined':
      return <span className='badge declined'>Declined</span>;
    case 'declined':
      return <span className='badge declined'>Declined</span>;
    default:
      return <span className='badge '>{data}</span>;
  }
};
