/* eslint-disable prefer-const */

export const checkEmpty = (obj, arr = []) => {
  for (let key in obj) {
    if (arr.includes(key)) {
      // nothing to do here
    } else {
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        return true;
      }

      if (typeof obj[key] === 'string' && obj[key].trim() === '') {
        return true;
      }
    }
  }

  return false;
};
