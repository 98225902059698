/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getContentList = createAsyncThunk(
  'getContentList',
  async ({ parameters, id }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: parameters,
      url: `api/content/${id}/view`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getContentData = createAsyncThunk(
  'getContentData',
  async (id, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      url: `api/content/show/${id}?include[]=trailers&include[]=user_content_video`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const submitContent = createAsyncThunk(
  'submitContent',
  async ({ parameters, id }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `api/content/${id}/create`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const updateContent = createAsyncThunk(
  'updateContent',
  async ({ parameters, id }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `api/content/update/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const deleteContent = createAsyncThunk('deleteContent', async (id, { rejectWithValue }) => {
  return await axios({
    method: 'delete',
    url: `api/content/delete/${id}`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const archiveContent = createAsyncThunk(
  'archiveContent',
  async ({ userId, contentId }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/content/archive/${userId}/${contentId}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const unarchiveContent = createAsyncThunk(
  'unarchiveContent',
  async ({ userId, contentId }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      url: `api/content/unarchive/${userId}/${contentId}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getCastList = createAsyncThunk(
  'getCastList',
  async (parameters, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      params: parameters,
      url: `/api/content/cast/view`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
