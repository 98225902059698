import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from 'reducers/authReducer';
import { sampleReducer } from 'reducers/sampleReducer';
import { profileReducer } from 'reducers/profileReducer';
import { contentReducer } from 'reducers/contentReducer';
import { dashboardReducer } from 'reducers/dashboardReducer';
import { payoutReducer } from 'reducers/payoutReducer';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sample: sampleReducer,
    content: contentReducer,
    profile: profileReducer,
    dashboard: dashboardReducer,
    payout: payoutReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
