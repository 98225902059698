/* eslint-disable camelcase */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';
// import { useParams } from 'react-router-dom';

// Components
import FormText from 'components/GlobalUIs/FormText';
import { updatePassword, selectAuth } from 'reducers/authReducer';
import SuccessModal from './components/SuccessModal';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';

// Helpers & Utils

// Import assets below
import styles from './index.module.scss';
import WebsiteLogo from 'assets/images/logo.png';
import LoginImage from 'assets/images/login Image.png';

const initialState = {
  confirmPassword: '',
  email: '',
  newPassword: '',
  token: '',
};

// Main component
const UpdatePassword = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // const params = useParams();

  const authState = useSelector(selectAuth);
  const { updatePasswordLogs } = authState;

  const [formData, setFormData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);

  const handleLogin = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      token: formData?.token,
      email: formData?.email,
      password: formData?.newPassword,
      password_confirmation: formData?.confirmPassword,
    };

    dispatch(updatePassword(params));
  };

  const emailProps = {
    type: 'email',
    name: 'Email',
    noLabel: true,
    haveClass: '',
    setState: setFormData,
    stateValue: formData?.email,
    placeholder: 'Email Address',
    required: true,
  };

  const passwordProps = {
    noLabel: true,
    type: 'password',
    name: 'New Password',
    setState: setFormData,
    placeholder: 'New Password',
    stateValue: formData?.newPassword,
    required: true,
  };

  const confirmPasswordProps = {
    noLabel: true,
    type: 'password',
    setState: setFormData,
    name: 'Confirm Password',
    placeholder: 'Confirm Password',
    stateValue: formData?.confirmPassword,
    required: true,
  };

  const submitButtonProps = {
    type: 'submit',
    variant: 'primary',
    value: 'Reset Password',
    className: styles?.submitButton,
    isDisabled: updatePasswordLogs?.isLoading,
  };

  // Render when token is found/not found
  useEffect(() => {
    if (window) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get('token');
      setFormData((prev) => {
        return {
          ...prev,
          token: token ? token : '',
        };
      });
    }
  }, [window]);

  const handleHideModal = () => {
    setShowModal(false);
  };

  renderSubmitCallback(updatePasswordLogs, handleLogin, setShowModal);

  return (
    <Container fluid>
      <div className={styles?.resetContainer}>
        <Form className={`shadow-sm ${styles?.loginForm}`} onSubmit={handleSubmit}>
          <Image src={WebsiteLogo} alt='Website Logo' />

          <div className={styles?.loginHeader}>
            <h6>Reset Password</h6>
            <p>Enter your new password</p>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...emailProps} />

            <FormText {...passwordProps} />

            <FormText {...confirmPasswordProps} />
          </div>

          <div className={styles?.marginContainer}>
            <ButtonSubmit {...submitButtonProps} />

            <div className={styles?.signup}>
              <Link className={styles?.link} to='/'>
                Return to login
              </Link>
            </div>
          </div>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>

        <SuccessModal show={showModal} onHide={handleHideModal} />
      </div>
    </Container>
  );
};

const renderSubmitCallback = (updatePasswordLogs, handleLogin, setShowModal) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = updatePasswordLogs;

    if (isSuccess) {
      setShowModal(true);

      setTimeout(() => {
        handleLogin();
      }, 2000);
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [updatePasswordLogs]);
};

export default UpdatePassword;
