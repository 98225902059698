// Change data from state using name

export const handleCheckBoxChange = (setState) => (e) => {
  const { checked, name } = e.target;

  console.log('NAME', name);
  console.log('checked', checked);

  setState((prev) => {
    return {
      ...prev,
      [name]: checked,
    };
  });
};
