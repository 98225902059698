/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Image, Row, Col, Container } from 'react-bootstrap';

// Components
import Sidebar from 'components/Sidebar';
import Content from './components/Dashboard';
import { selectAuth } from 'reducers/authReducer';
import { updateProfileData } from 'reducers/authReducer';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Assets
import styles from './index.module.scss';

// Main component
const Dashboard = () => {
  const dispatch = useDispatch();

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  useEffect(() => {
    const handleGetData = () => {
      dispatch(updateProfileData());
    };

    handleGetData();
  }, []);

  return (
    <PrivateLayout pageTitle='Dashboard'>
      <Container fluid>
        <Row className='g-1'>
          <Col sm={9}>
            <Content userInfo={userInfo} />
          </Col>
          <Col sm={3}>
            <Sidebar userInfo={userInfo} />
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default Dashboard;
