/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Button, OverlayTrigger, Popover } from 'react-bootstrap';

import TransactionModal from './transactionModal';
import {
  getRecentUploads,
  getdashboardState,
  getTransanctionHistory,
} from 'reducers/dashboardReducer';

import styles from './index.module.scss';
import gcashSample from 'assets/images/gcash.png';
import samplePicture from 'assets/images/avatar.jpg';
import paymayaSample from 'assets/images/paymaya.png';
import { ReactComponent as VideoSVG } from 'assets/svg/video.svg';
import { ReactComponent as EyeSVG } from 'assets/svg/eye small.svg';
import { ReactComponent as ActionSVG } from 'assets/svg/three dots.svg';
import { ReactComponent as FollowersSVG } from 'assets/svg/followers.svg';

const index = ({ userInfo }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const dashboardState = useSelector(getdashboardState);
  const { transactionHistoryList, recentUploadsList, transactionHistoryLogs } = dashboardState;

  const [showModal, setShowModal] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const handleViewProfile = () => {
    navigate('/my-profile ');
  };

  const handleHideModal = () => {
    setShowModal(false);
    setSelectedTransaction(null);
  };

  const handleRedicrectTransaction = () => {
    navigate('/payout-request');
  };

  getDashboardData(dispatch, userInfo);

  getTransactionHistoryState(transactionHistoryLogs, setTransactionHistory, transactionHistoryList);

  return (
    <div className={styles?.rightSidebar}>
      <div className={styles?.accountDetails}>
        <div className={styles?.header}>
          <h5>ACCOUNT</h5>

          <OverlayTrigger
            trigger='click'
            placement='bottom'
            overlay={
              <Popover id='TableAction'>
                <Popover.Body className='d-flex pop-up-cont justify-content-between p-0'>
                  <Button onClick={handleViewProfile} className='view-data'>
                    View
                  </Button>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant='secondary' className='popoverButton bg-transparent p-0 border-0'>
              <ActionSVG className={styles?.action} />
            </Button>
          </OverlayTrigger>
        </div>

        <div className={styles?.accountImage}>
          <Image alt='User Profile' src={userInfo?.attributes?.profile_pic || samplePicture} />
        </div>

        <div className={styles?.title}>
          <p>{userInfo?.attributes?.full_name}</p>
        </div>

        <div className={styles?.details}>
          <div className={styles?.column}>
            <div className={styles?.title}>
              <VideoSVG />
              {recentUploadsList?.meta?.pagination?.total}
            </div>

            <p>Total Videos</p>
          </div>

          <div className={styles?.divider} />

          <div className={styles?.column}>
            <div className={styles?.title}>
              <FollowersSVG />
              {userInfo?.attributes?.total_profile_views}
            </div>

            <p>Profile Views</p>
          </div>

          <div className={styles?.divider} />

          <div className={styles?.column}>
            <div className={styles?.title}>
              <EyeSVG />
              {userInfo?.attributes?.total_content_views}
            </div>

            <p>Content Views</p>
          </div>
        </div>
      </div>

      <div className={styles?.transactionHistory}>
        <div className={styles?.header}>
          <h5>TRANSACTION HISTORY</h5>

          <Button onClick={handleRedicrectTransaction} className={styles?.viewAll}>
            View All
          </Button>
        </div>

        <ul className={styles?.list}>
          {transactionHistory &&
            transactionHistory.length > 0 &&
            transactionHistory.map((data, idx) => {
              return (
                <li key={idx} className={styles?.data}>
                  <div className={styles?.listData}>
                    <Image src={data?.img} />

                    <p>
                      {data?.detail} <span>{data?.date}</span>
                    </p>

                    <div
                      onClick={() => {
                        setShowModal(true);
                        setSelectedTransaction(data);
                      }}
                      className={styles?.arrow}
                    ></div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>

      <TransactionModal
        show={showModal}
        onHide={handleHideModal}
        selectedTransaction={selectedTransaction}
      />
    </div>
  );
};

const getDashboardData = (dispatch, userInfo) => {
  useEffect(() => {
    if (userInfo?.id) {
      dispatch(
        getTransanctionHistory({
          id: userInfo?.id,
          payload: {
            per_page: 5,
          },
        }),
      );
    }
  }, [userInfo?.id]);
};

const getTransactionHistoryState = (
  transactionHistoryLogs,
  setTransactionHistory,
  transactionHistoryList,
) => {
  useEffect(() => {
    const { isFailed, isSuccess, type } = transactionHistoryLogs;

    if (isFailed) {
      toast.error(
        'Something went wrong in retrieving Transaction History. Please refresh the page',
      );
    }

    if (isSuccess) {
      const newData = transactionHistoryList?.data.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          img: getBankImage(dd?.attributes?.payment_channel),
          date: dd?.attributes?.created_at,
          detail: dd?.attributes?.amount,
        };
      });

      setTransactionHistory(newData);
    }
  }, [transactionHistoryLogs]);
};

const getBankImage = (type) => {
  switch (type) {
    case 'gcash':
      return gcashSample;
    case 'maya':
      return paymayaSample;
    case 'Gcash':
      return gcashSample;
    case 'Maya':
      return paymayaSample;
    default:
      break;
  }
};

export default index;
