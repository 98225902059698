/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Header from './components/Header';
import Content from './components/Content';
import { selectAuth } from 'reducers/authReducer';
import { updateProfileData } from 'reducers/authReducer';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { getProfileData, selectprofile } from 'reducers/profileReducer';

// Main component
const Faq = () => {
  const dispatch = useDispatch();

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const profileState = useSelector(selectprofile);
  const { profileData } = profileState;

  getDashboardData(dispatch, userInfo);

  return (
    <PrivateLayout pageTitle='Payout Request'>
      <Container fluid>
        <Row>
          <Col>
            <Header userInfo={userInfo} profileData={profileData} />

            <Content userInfo={userInfo} />
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

const getDashboardData = (dispatch, userInfo) => {
  useEffect(() => {
    if (userInfo?.id) {
      dispatch(getProfileData({}));
    }

    const handleGetData = () => {
      dispatch(updateProfileData());
    };

    handleGetData();
  }, [userInfo?.id]);
};

export default Faq;
