/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Image, Button, Popover, OverlayTrigger } from 'react-bootstrap';

import ConfirmModal from './Modal';
import VideoJS from 'components/VideoJS';
import { objectCleaner } from 'helpers/objectCleaner';
import AzureMediaPlayer from 'components/AzureMediaPlayer';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getContentList, getcontentState } from 'reducers/contentReducer';
import { archiveContent, deleteContent, unarchiveContent } from 'reducers/contentReducer';

import styles from './ContentList.module.scss';
import actionPng from 'assets/images/action.png';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';

const ContentList = ({ userInfo }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const playerRef = useRef(null);

  const contentState = useSelector(getcontentState);

  const { contentList, contentLogs } = contentState;

  const [modalConfig, setModalConfig] = useState({
    title: '',
    onHide: null,
    isShow: false,
    description: '',
    handleConfirm: null,
  });
  const [params, setParams] = useState({
    q: '',
    id: '',
    page: 1,
    per_page: 5,
    filter_status: '',
    include: ['user_content_video'],
  });

  const confirmDelete = async (data) => {
    const response = await dispatch(deleteContent(data?.id));

    if (response?.meta?.requestStatus === 'fulfilled') {
      toast.success('Content successfully deleted.');
      hideModal();

      const newObj = {
        page: 1,
        per_page: 5,
        q: params?.q,
        include: params?.include,
        filter_status: params?.filter_status,
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(
        getContentList({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const handleDelete = (data) => {
    setModalConfig({
      data: data,
      isShow: true,
      onHide: hideModal,
      title: 'Confirm Delete',
      description: 'This action cannot be undone, still wanna proceed?',
      handleConfirm: confirmDelete,
    });
  };

  const confirmArchive = async (data) => {
    const response = await dispatch(archiveContent({ contentId: data?.id, userId: userInfo?.id }));

    if (response?.meta?.requestStatus === 'fulfilled') {
      toast.success('Content successfully archived.');
      hideModal();

      const newObj = {
        page: 1,
        per_page: 5,
        q: params?.q,
        include: params?.include,
        filter_status: params?.filter_status,
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(
        getContentList({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const confirmUnachive = async (data) => {
    const response = await dispatch(
      unarchiveContent({ contentId: data?.id, userId: userInfo?.id }),
    );

    if (response?.meta?.requestStatus === 'fulfilled') {
      toast.success('Content successfully unarchived.');
      hideModal();

      const newObj = {
        page: 1,
        per_page: 5,
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(
        getContentList({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const handleArchive = (data) => {
    setModalConfig({
      data: data,
      isShow: true,
      onHide: hideModal,
      title: 'Confirm Archive',
      description: 'Proceed to archive this content?',
      handleConfirm: confirmArchive,
    });
  };

  const handleUnarchive = (data) => {
    setModalConfig({
      data: data,
      isShow: true,
      onHide: hideModal,
      title: 'Confirm Unarchive',
      description: 'Proceed to unarchive this content?',
      handleConfirm: confirmUnachive,
    });
  };

  const hideModal = () => {
    setModalConfig({
      title: '',
      data: null,
      onHide: null,
      isShow: false,
      description: '',
      handleConfirm: null,
    });
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const setVideoOptions = (data) => {
    return {
      fluid: true,
      controls: true,
      autoplay: false,
      responsive: true,
      aspectRatio: '16:9',
      poster: data?.attributes?.thumbnail,
      sources: [
        {
          src: data?.attributes?.video_path_dash,
          type: 'application/dash+xml',
        },
      ],
    };
  };

  const handleCancel = () => {
    navigate('/my-profile');
  };

  const handleSave = () => {
    // api here the data is inside formData
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      q: params?.q,
      page: page,
      per_page: 5,
      include: params?.include,
      filter_status: params?.filter_status,
    };

    const { parameters } = objectCleaner(newObj);

    backToTop();

    dispatch(
      getContentList({
        parameters: parameters,
        id: params?.id,
      }),
    );
  };

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  setUserInfo(userInfo, params, setParams);

  console.log('contentList', contentList);

  return (
    <div className={styles?.channelContainer}>
      <h4>Channel and Profile Customization</h4>

      <div className={styles?.formContainer}>
        <ConditionalRender
          condition={contentLogs?.isLoading}
          renderIf={
            <div className={styles?.loading}>
              <LoadingSVG />
            </div>
          }
          renderElse={
            contentList?.data &&
            contentList?.included &&
            contentList?.data.length > 0 &&
            contentList?.data.map((data, idx) => {
              const contentId = data?.relationships?.user_content_video?.data?.id;
              const video = contentList?.included.find((d) => +d?.id === +contentId);

              return (
                <div key={idx} className={styles?.mainContent}>
                  <div className={styles?.header}>
                    <div className={styles?.userInfo}>
                      <Image src={userInfo?.attributes?.profile_pic} />

                      <p>
                        {userInfo?.attributes?.full_name}{' '}
                        <span>{userInfo?.attributes?.created_at}</span>
                      </p>
                    </div>

                    <OverlayTrigger
                      trigger='click'
                      placement='bottom'
                      overlay={
                        <Popover id='TableAction'>
                          <Popover.Body className='d-flex pop-up-cont justify-content-between p-0'>
                            <div className='column-direction'>
                              {data?.attributes?.content_status !== 'archived' ? (
                                <Button onClick={() => handleArchive(data)} className='view-data'>
                                  Archive
                                </Button>
                              ) : (
                                <Button onClick={() => handleUnarchive(data)} className='view-data'>
                                  Unarchive
                                </Button>
                              )}

                              <Button onClick={() => handleDelete(data)} className='view-data'>
                                Delete
                              </Button>
                            </div>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        variant='secondary'
                        className='popoverButton bg-transparent p-0 border-0'
                      >
                        <Image src={actionPng} className={styles?.action} />
                      </Button>
                    </OverlayTrigger>
                  </div>

                  <div className={styles?.details}>
                    {/* <VideoJS options={setVideoOptions(data)} onReady={handlePlayerReady} /> */}

                    <AzureMediaPlayer
                      thumbnail={data?.attributes?.thumbnail}
                      video={video?.attributes?.video_path_dash}
                    />

                    <p className={styles?.description}>
                      <b>Description:</b>
                      <span>{data?.attributes?.description}</span>
                    </p>

                    <b>{data?.attributes?.duration}</b>
                  </div>
                </div>
              );
            })
          }
        />

        <div className={styles?.mainContent}>
          <TablePagination
            data={contentList}
            setParams={setParams}
            loading={contentLogs?.isLoading}
            handlePaginationClick={handlePaginationClick}
          />
        </div>
      </div>

      {/* <div className={styles?.actionButtons}>
        <Button className={styles?.save} onClick={handleSave}>
          Save
        </Button>
        <Button className={styles?.cancel} onClick={handleCancel}>
          Cancel
        </Button>
      </div> */}

      <ConfirmModal {...modalConfig} />
    </div>
  );
};

const setUserInfo = (userInfo, params, setParams) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.id) {
      setParams((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
        };
      });

      const newObj = {
        per_page: 5,
        q: params?.q,
        page: params?.page,
        include: params?.include,
        filter_status: params?.filter_status,
      };

      const { parameters } = objectCleaner(newObj);

      console.log('setUserInfo...');

      dispatch(
        getContentList({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    }
  }, [userInfo?.id]);
};

export default ContentList;
