/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Form, Image, Modal, Button } from 'react-bootstrap';

import FormText from 'components/GlobalUIs/FormText';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';

import styles from './Modal.module.scss';
import ApproveIcon from 'assets/images/approve.png';
import { toast } from 'react-toastify';

const initialState = {
  password: '',
  confirmPassword: '',
  currentPassword: '',
};

const PasswordModal = ({ onHide, title, data, handleConfirm, isShow, ...rest }) => {
  const [formData, setFormData] = useState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData?.password.length < 8) {
      toast.error('Password needs to be atleast 8 characters long');
      return;
    }

    if (formData?.password !== formData?.confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!formData?.password.match(passwordRegex)) {
      toast.error('Password must contain at least one uppercase, one lowercase, and one numeric.');
      return;
    }

    handleConfirm(formData);
    setFormData(initialState);
  };

  const currentPasswordProps = {
    noLabel: true,
    required: true,
    type: 'password',
    name: 'Current Password',
    setState: setFormData,
    placeholder: 'Current Password',
    stateValue: formData?.currentPassword,
  };

  const passwordProps = {
    noLabel: true,
    required: true,
    type: 'password',
    name: 'Password',
    setState: setFormData,
    placeholder: 'New Password',
    stateValue: formData?.password,
  };

  const confirmPasswordProps = {
    noLabel: true,
    required: true,
    type: 'password',
    setState: setFormData,
    name: 'Confirm Password',
    placeholder: 'Confirm Password',
    stateValue: formData?.confirmPassword,
  };

  const submitButtonProps = {
    value: 'UPDATE',
    type: 'submit',
    variant: 'primary',
    className: styles?.confirm,
    // isDisabled: loginLogs?.isLoading,
  };

  return (
    <Modal
      {...rest}
      size='md'
      centered
      show={isShow}
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header}>
        <Modal.Title id='account-created' className={styles?.title}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        <Form className={styles?.loginForm} onSubmit={handleSubmit}>
          <FormText {...currentPasswordProps} />

          <FormText {...passwordProps} />

          <FormText {...confirmPasswordProps} />

          <div className={styles?.buttons}>
            <ButtonSubmit {...submitButtonProps} />

            <Button className={styles?.close} onClick={onHide}>
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PasswordModal;
