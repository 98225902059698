/* eslint-disable no-unused-vars */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import VideoJS from 'components/VideoJS';

import styles from './modal.module.scss';

const PreviewVideoModal = (props) => {
  const { onHide, selectedPreview } = props;

  const videoOptions = {
    fluid: true,
    controls: true,
    autoplay: true,
    responsive: true,
    aspectRatio: '16:9',
    sources: [
      {
        src: selectedPreview?.video?.attributes?.video_path_dash,
        type: 'application/dash+xml',
      },
    ],
  };

  return (
    <Modal
      {...props}
      size='md'
      centered
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header}>
        <Modal.Title id='account-created' className={styles?.title}>
          View Video
        </Modal.Title>
      </Modal.Header>
      {selectedPreview && (
        <Modal.Body className={styles?.body}>
          <div className={styles?.previewVideo}>
            <VideoJS options={videoOptions} className='dash-video' />
          </div>

          <Button className={styles?.close} onClick={onHide}>
            OK
          </Button>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default PreviewVideoModal;
