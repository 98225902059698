/* eslint-disable no-unused-vars */
/* eslint-disable no-multi-assign */

import videojs from '!video.js';
import React, { useEffect, useRef, useMemo } from 'react';

import 'video.js/dist/video-js.css';

const VideoJS = ({ options, onReady }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const playerOptions = useMemo(() => {
    // Convert options object to array of key-value pairs
    const optionsArray = Object.entries(options);

    // Filter out any options whose values are functions
    const filteredOptions = optionsArray.filter(([key, value]) => typeof value !== 'function');

    // Convert array of key-value pairs back to object
    return Object.fromEntries(filteredOptions);
  }, [options?.sources[0].name]);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, playerOptions, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      }));
    } else {
      const player = playerRef.current;

      // Set new source for the player
      player.src(playerOptions.sources);

      // Set autoplay based on new option value
      player.autoplay(playerOptions.autoplay);
    }
  }, [playerOptions.sources, playerOptions.autoplay]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export default VideoJS;
