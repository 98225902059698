/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Image, FormControl, Button } from 'react-bootstrap';

import Analytics from './Analytics';
import ContentList from './ContentList';
import { selectAuth } from 'reducers/authReducer';
import ChannelAndProfile from './ChannelAndProfile';
import { updateUserinfo } from 'reducers/authReducer';
import { updateProfile } from 'reducers/profileReducer';
import ConditionalRender from 'components/ConditionalRender';

import styles from './Content.module.scss';
import videoSample from 'assets/videos/video.mp4';
import sampleAvatar from 'assets/images/avatar.jpg';
import thumbnaiSample from 'assets/images/thumb1.png';
import { ReactComponent as UploadSVG } from 'assets/svg/upload.svg';
import { ReactComponent as ProfileSVG } from 'assets/svg/my-profile.svg';
import { ReactComponent as ContentSVG } from 'assets/svg/my-content.svg';
import { ReactComponent as AnalyticsSVG } from 'assets/svg/my-analytics.svg';

const sampleData = {
  totalVideos: '10',
  avatar: sampleAvatar,
  country: 'Philippines',
  email: 'jdc@email.com',
  name: 'Juan Dela Cruz',
  phone: '+639171234567',
  title: 'Content Creator',
  totalViewCount: '10,000',
  payoutBalance: 'Php 50,000.00',
  transactions: [
    {
      id: 1,
      bank: 'Maya',
      amount: '-Php 10,000.00',
      date: 'December 28, 2022 at 7:00 pm',
    },
    {
      id: 2,
      bank: 'Maya',
      amount: '-Php 10,000.00',
      date: 'December 27, 2022 at 7:00 pm',
    },
    {
      id: 3,
      bank: 'Gcash',
      amount: '-Php 10,000.00',
      date: 'December 25, 2022 at 7:00 pm',
    },
    {
      id: 1,
      bank: 'Gcash',
      amount: '-Php 10,000.00',
      date: 'December 10, 2022 at 7:00 pm',
    },
  ],
};

const sampleContentList = [
  {
    isApproved: true,
    video: videoSample,
    date: 'Dec. 6, 2022',
    thumbnail: thumbnaiSample,
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea',
    duration: '3 Hours',
  },
  {
    isApproved: false,
    video: videoSample,
    date: 'Dec. 6, 2022',
    thumbnail: thumbnaiSample,
    description:
      'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea',
    duration: '3 Hours',
  },
];

const Header = () => {
  const dispatch = useDispatch();

  const hiddenFileInput = useRef(null);
  const bannerFileInput = useRef(null);

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const [profilePicture, setProfilePicture] = useState();
  const [showScreen, setShowScreen] = useState(0);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleBannerClick = () => {
    bannerFileInput.current.click();
  };

  const handleChangeProfile = async (e) => {
    if (e.target.files.length > 0) {
      // setProfilePicture(e.target.files[0]);
      const formData = new FormData();
      formData.append('profile_pic', e.target.files[0]);

      const response = await dispatch(
        updateProfile({
          id: userInfo?.id,
          parameters: formData,
        }),
      );

      if (response?.meta?.requestStatus === 'fulfilled') {
        toast.success('Successfully updated profile picture');
        setProfilePicture(response.payload?.data?.data?.attributes?.profile_pic);
        dispatch(updateUserinfo(response.payload?.data?.data));
      } else {
        if (response?.payload?.errors) {
          const { errors } = response.payload;

          errors.forEach((data) => {
            toast.error(data?.detail);
          });
        } else {
          toast.error('Something went wrong. Please try to submit or refresh the page.');
        }
      }
    }
  };

  const handleChangeBanner = async (e) => {
    if (e.target.files.length > 0) {
      // setProfilePicture(e.target.files[0]);
      const formData = new FormData();
      formData.append('profile_banner', e.target.files[0]);

      const response = await dispatch(
        updateProfile({
          id: userInfo?.id,
          parameters: formData,
        }),
      );

      if (response?.meta?.requestStatus === 'fulfilled') {
        toast.success('Successfully updated profile banner');
        setProfilePicture(response.payload?.data?.data?.attributes?.profile_banner);
        dispatch(updateUserinfo(response.payload?.data?.data));
      } else {
        if (response?.payload?.errors) {
          const { errors } = response.payload;

          errors.forEach((data) => {
            toast.error(data?.detail);
          });
        } else {
          toast.error('Something went wrong. Please try to submit or refresh the page.');
        }
      }
    }
  };

  const profileUpdateConfigs = {
    type: 'file',
    name: 'profile',
    ref: hiddenFileInput,
    onChange: handleChangeProfile,
    className: styles?.hiddenProfile,
    accept: 'image/png, image/gif, image/jpeg',
  };

  const bannerUpdateConfigs = {
    type: 'file',
    name: 'banner',
    ref: bannerFileInput,
    onChange: handleChangeBanner,
    className: styles?.hiddenBanner,
    accept: 'image/png, image/gif, image/jpeg',
  };

  renderProfile(userInfo, setProfilePicture);

  return (
    <div
      className={styles?.header}
      style={{ background: `url('${userInfo?.attributes?.profile_banner}')` }}
    >
      <div className={styles?.bannerOverlay} onClick={handleBannerClick}>
        <UploadSVG />

        <p>Update Profile Banner</p>
      </div>

      <FormControl {...bannerUpdateConfigs} />

      <div className={styles?.innerHeader}>
        <Row className='w-100'>
          <Col className='h-100' xs={3}>
            <div className={styles?.selectionContainer}>
              <div className={styles?.imageUpload} onClick={handleClick}>
                <Image src={profilePicture} />

                <div className={styles?.overlay}>
                  <UploadSVG />

                  <p>Update Profile Picture</p>
                </div>

                <FormControl {...profileUpdateConfigs} />
              </div>

              <div className={styles?.selection}>
                <ul>
                  <li>
                    <Button
                      onClick={() => setShowScreen(0)}
                      className={showScreen === 0 ? styles?.active : ''}
                    >
                      <ProfileSVG />
                      Personal Information
                    </Button>
                  </li>

                  <li>
                    <Button
                      onClick={() => setShowScreen(1)}
                      className={showScreen === 1 ? styles?.active : ''}
                    >
                      <ContentSVG />
                      Content
                    </Button>
                  </li>

                  <li>
                    <Button
                      onClick={() => setShowScreen(2)}
                      className={showScreen === 2 ? styles?.active : ''}
                    >
                      <AnalyticsSVG />
                      Analytics
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={9}>
            <ConditionalRender condition={showScreen === 0} renderIf={<ChannelAndProfile />} />

            <ConditionalRender
              condition={showScreen === 1}
              renderIf={<ContentList userInfo={userInfo} />}
            />

            <ConditionalRender
              condition={showScreen === 2}
              renderIf={<Analytics sampleContentList={sampleContentList} sampleData={sampleData} />}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const renderProfile = (userInfo, setProfilePicture) => {
  useEffect(() => {
    if (userInfo?.id) {
      setProfilePicture(userInfo?.attributes?.profile_pic);
    }
  }, [userInfo]);
};

export default Header;
