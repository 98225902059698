/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Cast from './Cast';
import SuccessModal from './Successmodal';
import { checkEmpty } from 'helpers/validator';
import { formatTime } from 'helpers/getDuration';
import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUIs/FormText';
import VideoUpload from 'components/GlobalUIs/VideoUpload';
import AzureMediaPlayer from 'components/AzureMediaPlayer';
import FileUploads from 'components/GlobalUIs/FileUploads';
import ConditionalRender from 'components/ConditionalRender';
import FormSelection from 'components/GlobalUIs/FormSelection';
import ThumbnailUpload from 'components/GlobalUIs/ThumbnailUpload';
import { updateContent, getcontentState, getContentData } from 'reducers/contentReducer';

import styles from './index.module.scss';
import sampleThumbnail from 'assets/images/thumb1.png';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';
import { ReactComponent as ButtonLoadingSVG } from 'assets/svg/button_loading.svg';

const initialState = {
  srt: [],
  cast: [],
  title: '',
  category: '',
  thumbnail: [],
  description: '',
  audienceRestriction: '',
  // familyFriendlyContent: '',
};

const Content = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const userState = useSelector(selectAuth);
  const contentState = useSelector(getcontentState);

  const { userInfo } = userState;
  const { singleData, singleContentLogs, updateContentLogs } = contentState;

  const { data, included } = singleData;

  const [showModal, setShowModal] = useState(false);
  const [video, setVideo] = useState({
    video: [],
    trailer: [],
    duration: '',
  });
  const [formData, setFormData] = useState(initialState);

  const handleVideoMeta = (data) => {
    const file = data;

    const audio = document.createElement('audio');

    audio.addEventListener('loadedmetadata', () => {
      const result = formatTime(audio.duration);

      setVideo((prev) => {
        return {
          ...prev,
          duration: result,
        };
      });
    });

    audio.src = URL.createObjectURL(file);
  };

  const handleCancel = () => {
    navigate('/content-submission');
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          const base64Image = reader.result;
          resolve(base64Image);
        }
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    const params = new FormData();

    const result = checkEmpty(formData, ['cast', 'srt']); // Second parameter is exclude
    const result2 = checkEmpty(video);
    // const result3 = formData?.srt.some((item) => !item?.file || item?.language === '');

    if (result || result2) {
      toast.error('Please fill in the empty fields');
      return;
    }

    if (formData?.cast.length > 6) {
      toast.error('Can cast only up to six actors/actress');
      return;
    }

    for (const key in formData) {
      if (key === 'cast') {
        // Do nothing
      } else if (key === 'thumbnail') {
        if (typeof formData[key][0] !== 'string') {
          params.append(key, formData[key][0]);
        }
      } else if (key === 'audienceRestriction') {
        params.append('audience', formData[key]);
      } else {
        params.append(key, formData[key]);
      }
    }

    for (const key in video) {
      if (key === 'video' || key === 'trailer') {
        if (typeof video[key][0] !== 'string') {
          params.append(key, video[key][0]);
        }
      } else {
        params.append(key, video[key]);
      }
    }

    if (formData?.cast.length > 0) {
      for (let i = 0; i < formData?.cast.length; i++) {
        const img = formData?.cast[i].cast_picture;

        if (img instanceof File) {
          const imgRes = await convertFileToBase64(formData?.cast[i]?.cast_picture);

          const newData = {
            cast_name: formData?.cast[i]?.cast_name,
            cast_role_name: formData?.cast[i]?.cast_role_name,
            cast_picture: imgRes,
          };

          params.append(`cast[${i}]`, JSON.stringify(newData));
        } else {
          const oldData = {
            cast_name: formData?.cast[i]?.cast_name,
            cast_role_name: formData?.cast[i]?.cast_role_name,
            cast_picture: formData?.cast[i]?.cast_picture,
          };

          params.append(`cast[${i}]`, JSON.stringify(oldData));
        }
      }
    } else {
      params.append(`cast[]`, JSON.stringify([]));
    }

    if (formData?.srt.length > 0 && formData?.srt[0]?.file !== null) {
      for (let ii = 0; ii < formData?.srt.length; ii++) {
        const fileName = formData?.srt[ii].file[0];

        if (fileName instanceof File) {
          const srtFile = await convertFileToBase64(formData?.srt[ii].file[0]);

          const srcData = {
            file: srtFile,
            language: formData?.srt[ii].language?.value,
          };

          params.append(`subtitle[${ii}]`, JSON.stringify(srcData));
        } else {
          const srcData = {
            id: formData?.srt[ii].id,
            file: formData?.srt[ii].file,
            language: formData?.srt[ii].language?.value,
          };

          params.append(`subtitle[${ii}]`, JSON.stringify(srcData));
        }
      }
    }

    params.append('is_approved', 0);
    params.append('content_status', 'pending');

    // for (const [key, value] of params.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    // return;

    toast.info('Submitting data, Please wait a moment.');

    const response = await dispatch(updateContent({ parameters: params, id: data?.id }));

    if (response?.meta?.requestStatus === 'fulfilled') {
      setShowModal(true);

      setTimeout(() => {
        navigate('/content-submission');
      }, [3000]);
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const videoUploadConfigs = {
    name: 'video',
    preload: 'none',
    setState: setVideo,
    stateValue: video?.video,
    videoMeta: handleVideoMeta,
  };

  const trailerUploadConfigs = {
    name: 'trailer',
    preload: 'none',
    setState: setVideo,
    stateValue: video?.trailer,
    videoMeta: () => {},
  };

  const thumbnailConfigs = {
    name: 'thumbnail',
    setState: setFormData,
    stateValue: formData?.thumbnail,
  };

  const titleConfigs = {
    type: 'text',
    name: 'Title',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Title',
    stateValue: formData?.title,
  };

  const fileConfigs = {
    name: 'srt',
    label: 'SRT',
    accept: '.srt',
    setState: setFormData,
    stateValue: formData?.srt,
  };

  const descriptionConfigs = {
    rows: '8',
    as: 'textarea',
    type: 'textarea',
    name: 'Description',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Description',
    stateValue: formData?.description,
  };

  const durationConfigs = {
    type: 'text',
    readOnly: true,
    name: 'Duration',
    haveClass: 'grey-bg',
    setState: setVideo,
    placeholder: 'Enter Duration',
    stateValue: video?.duration,
  };

  const categoryConfigs = {
    name: 'Category',
    haveClass: 'grey-bg',
    setState: setFormData,
    stateValue: formData?.category,
    options: [
      'Action',
      'Horror',
      'Comedy',
      'Drama',
      'Sci-Fi',
      'Romance',
      'Documentary',
      'Anime',
      'Cartoons',
      'Crime',
      'Mystery',
      'Vlog',
    ],
  };

  const audienceRestrictionConfigs = {
    haveClass: 'grey-bg',
    setState: setFormData,
    name: 'Audience Restriction',
    stateValue: formData?.audienceRestriction,
    options: [
      'All',
      'General audience',
      'Mature',
      'Parental guidance',
      'Restricted',
      'No one under 17',
    ],
  };

  // const familyFriendlyContentConfigs = {
  //   haveClass: 'grey-bg',
  //   setState: setFormData,
  //   options: ['Yes', 'No'],
  //   name: 'Family Friendly Content',
  //   stateValue: formData?.familyFriendlyContent,
  // };

  const submitConfig = {
    as: 'input',
    readOnly: true,
    value: 'Submit',
    onClick: handleSubmit,
    className: styles?.submit,
    disabled: updateContentLogs?.isLoading,
  };

  const cancelConfig = {
    as: 'input',
    readOnly: true,
    value: 'Cancel',
    className: styles?.cancel,
    onClick: handleCancel,
  };

  callContentData(id, dispatch);

  setDataFromState(data, included, setFormData, setVideo);

  return (
    <>
      <div className={styles?.dashboard}>
        <div className={styles?.uploadContent}>
          <div className={styles?.header}>
            <h5>EDIT CONTENT</h5>
          </div>

          <ConditionalRender
            condition={singleContentLogs.isLoading}
            renderIf={
              <div className={styles?.loading}>
                <LoadingSVG />
              </div>
            }
            renderElse={
              <div className={styles?.content}>
                <Row>
                  <Col sm={7}>
                    <div className={styles?.uploadContainer}>
                      <div>
                        <label className={styles?.label}>Video</label>
                        <VideoUpload {...videoUploadConfigs} />
                      </div>

                      <div>
                        <label className={styles?.label}>Trailer</label>
                        <VideoUpload {...trailerUploadConfigs} />
                      </div>

                      <div>
                        <label className={styles?.label}>Thumbnail</label>
                        <ThumbnailUpload {...thumbnailConfigs} />
                      </div>

                      <div>
                        <label className={styles?.label}>Subtitle</label>
                        <FileUploads {...fileConfigs} />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className={styles?.detailsContainer}>
                      {singleData?.data?.attributes?.decline_reason && (
                        <p className={styles?.decline}>
                          Decline Reason:{' '}
                          <span>{singleData?.data?.attributes?.decline_reason}</span>
                        </p>
                      )}

                      <FormText {...titleConfigs} />

                      <FormText {...descriptionConfigs} />

                      <Cast formData={formData} setFormData={setFormData} />

                      <div className={styles?.settings}>
                        <FormText {...durationConfigs} />

                        <FormSelection {...categoryConfigs} />

                        <FormSelection {...audienceRestrictionConfigs} />
                      </div>

                      <div className={styles?.actions}>
                        {/* <div className={styles?.groupFamily}>
                      <FormSelection {...familyFriendlyContentConfigs} />
                    </div> */}

                        <div className={styles?.buttons}>
                          <Button {...submitConfig} />
                          <Button {...cancelConfig} />

                          {updateContentLogs?.isLoading && (
                            <ButtonLoadingSVG className={styles?.loading} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            }
          />
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </>
  );
};

const callContentData = (id, dispatch) => {
  useEffect(() => {
    if (id) {
      dispatch(getContentData(id));
    }
  }, [id]);
};

const setDataFromState = (data, included, setFormData, setVideo) => {
  useEffect(() => {
    if (data) {
      setFormData((prev) => {
        const castArr = data?.attributes?.cast?.map((dd) => {
          let data;

          if (typeof dd === 'string') {
            data = JSON.parse(dd);
          } else {
            data = dd;
          }

          return {
            id: data?.id,
            value: data?.id,
            label: data?.cast_name,
            cast_name: data?.cast_name,
            cast_picture: data?.cast_picture,
            cast_role_name: data?.cast_role_name,
          };
        });

        let subArr = [
          {
            file: null,
            id: uuidv4(),
            language: '',
          },
        ];

        if (data?.attributes?.subtitle) {
          subArr = data?.attributes?.subtitle.map((dd) => {
            let dataFile;

            if (typeof dd === 'string') {
              dataFile = JSON.parse(dd);
            } else {
              dataFile = dd;
            }

            return {
              id: uuidv4(),
              file: dataFile?.file,
              language: { label: dataFile?.language, value: dataFile?.language },
            };
          });
        }

        return {
          ...prev,
          srt: subArr,
          cast: castArr,
          title: data?.attributes?.title,
          category: data?.attributes?.category,
          thumbnail: [data?.attributes?.thumbnail],
          description: data?.attributes?.description,
          audienceRestriction: data?.attributes?.audience,
        };
      });

      const videoData = included.find((dd) => dd.type === 'user_content_video');
      const trailerData = included.find((dd) => dd.type === 'user_content_trailers');

      setVideo({
        trailer: [trailerData?.attributes?.video_path_dash],
        video: [videoData?.attributes?.video_path_dash],
        duration: data?.attributes?.duration,
      });
    }
  }, [data]);
};

export default Content;
