/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, Table, Button, OverlayTrigger, Popover } from 'react-bootstrap';

import PreviewVideoModal from './PreviewVideoModal';
import { objectCleaner } from 'helpers/objectCleaner';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getRecentUploads, getdashboardState } from 'reducers/dashboardReducer';

import styles from './index.module.scss';
import LikeIcon from 'assets/images/likes.png';
import actionIcon from 'assets/images/action.png';
import EyeIcon from 'assets/images/green eye.png';
import EarningsIcon from 'assets/images/earnings.png';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';

const Content = ({ userInfo }) => {
  const dispatch = useDispatch();

  const dashboardState = useSelector(getdashboardState);
  const { recentUploadsList, recentUploadsLogs } = dashboardState;

  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [params, setParams] = useState({
    id: '',
    page: 1,
    per_page: 5,
  });

  const handlePaginationClick = ({ page }) => {
    setParams((prev) => {
      return {
        ...prev,
        id: userInfo?.id,
      };
    });

    const newObj = {
      page: page,
      per_page: 5,
      filter_status: 'approved',
      [`include[]`]: 'user_content_video',
    };

    const { parameters } = objectCleaner(newObj);

    dispatch(getRecentUploads({ id: userInfo?.id, parameters: parameters }));
  };

  const convertToPhilippineMoney = (number) => {
    return `Php ${number}`;

    // return 'Php ' + number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  const handleHideModal = () => {
    setShowModal(false);
    setSelectedPreview(null);
  };

  getDashboardData(dispatch, userInfo, params, setParams);

  getRecentUploadState(recentUploadsLogs, setTableData, recentUploadsList);

  return (
    <div className={styles?.dashboard}>
      <div className={styles?.cardList}>
        <div className={styles?.card}>
          <div className={styles?.header}>
            <p className={styles?.title}>
              <Image src={EyeIcon} />
              TOTAL VIEW COUNT
            </p>

            {/* <ActionSVG className={styles?.action} /> */}
          </div>

          <div className={styles?.content}>
            <h4>{userInfo?.attributes?.total_content_views}</h4>
            <div className={`${styles?.box} ${styles?.green}`}></div>
            {/* <p>
              <span className={styles?.green}>+ 20%</span> than last week
            </p> */}
          </div>
        </div>

        <div className={styles?.card}>
          <div className={styles?.header}>
            <p className={styles?.title}>
              <Image src={EarningsIcon} />
              FOLLOWERS
            </p>

            {/* <ActionSVG className={styles?.action} /> */}
          </div>

          <div className={styles?.content}>
            <h4>{userInfo?.attributes?.total_followers}</h4>
            <div className={`${styles?.box} ${styles?.blue}`}></div>
            {/* <p>
              <span className={styles?.blue}>+ 20%</span> than last week
            </p> */}
          </div>
        </div>

        <div className={styles?.card}>
          <div className={styles?.header}>
            <p className={styles?.title}>
              <Image src={LikeIcon} />
              LIKES
            </p>

            {/* <ActionSVG className={styles?.action} /> */}
          </div>

          <div className={styles?.content}>
            <h4>{userInfo?.attributes?.total_content_likes}</h4>
            <div className={`${styles?.box} ${styles?.red}`}></div>
            {/* <p>
              <span className={styles?.red}>+ 20%</span> than last week
            </p> */}
          </div>
        </div>

        <div className={styles?.card}>
          <div className={styles?.header}>
            <p className={styles?.title}>
              <Image src={EarningsIcon} />
              EARNINGS
            </p>

            {/* <ActionSVG className={styles?.action} /> */}
          </div>

          <div className={styles?.content}>
            <h4>{convertToPhilippineMoney(userInfo?.attributes?.total_earnings)}</h4>
            <div className={`${styles?.box} ${styles?.blue}`}></div>
            {/* <p>
              <span className={styles?.blue}>+ 20%</span> than last week
            </p> */}
          </div>
        </div>
      </div>

      <div className={styles?.recentUploads}>
        <div className={styles?.header}>
          <h5>RECENT UPLOADS</h5>
        </div>

        <Table responsive className={styles?.UploadTable}>
          <thead>
            <tr>
              <th>THUMBNAIL</th>
              <th>VIDEO TITLE</th>
              <th>DESCRIPTION</th>
              <th>DURATION</th>
              <th>CATEGORY</th>
              <th>STREAM TIME</th>
              <th>EARNINGS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ConditionalRender
              condition={recentUploadsLogs?.isLoading}
              renderIf={
                <tr>
                  <td colSpan={7} className={styles?.loading}>
                    <LoadingSVG />
                  </td>
                </tr>
              }
              renderElse={
                tableData &&
                tableData.length > 0 &&
                tableData.map((data) => {
                  return (
                    <tr key={data?.id}>
                      <td>
                        <Image className={styles?.thumnbnail} src={data?.thumb} />
                      </td>
                      <td>{data?.title}</td>
                      <td>{data?.description}</td>
                      <td>{data?.duration}</td>
                      <td>{data?.category}</td>
                      <td>{data?.streamTime}</td>
                      <td>{convertToPhilippineMoney(data?.total_earnings)}</td>
                      <td>
                        <OverlayTrigger
                          trigger='click'
                          placement='bottom'
                          overlay={
                            <Popover id='TableAction'>
                              <Popover.Body className='d-flex pop-up-cont justify-content-between p-0'>
                                <Button
                                  onClick={() => {
                                    setShowModal(true);
                                    setSelectedPreview(data);
                                  }}
                                  className='view-data'
                                >
                                  View
                                </Button>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Button
                            variant='secondary'
                            className='popoverButton bg-transparent p-0 border-0'
                          >
                            <Image src={actionIcon} className={styles?.action} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })
              }
            />
          </tbody>
        </Table>

        <TablePagination
          setParams={setParams}
          data={recentUploadsList}
          loading={recentUploadsLogs?.isLoading}
          handlePaginationClick={handlePaginationClick}
        />

        <PreviewVideoModal
          show={showModal}
          onHide={handleHideModal}
          selectedPreview={selectedPreview}
        />
      </div>
    </div>
  );
};

const getDashboardData = (dispatch, userInfo, params, setParams) => {
  useEffect(() => {
    if (userInfo?.id) {
      setParams((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
        };
      });

      const newObj = {
        per_page: 5,
        page: params?.page,
        filter_status: 'approved',
        [`include[]`]: 'user_content_video',
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(
        getRecentUploads({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    }
  }, [userInfo?.id]);
};

const getRecentUploadState = (recentUploadsLogs, setTableData, recentUploadsList) => {
  useEffect(() => {
    const { isFailed, isSuccess, type } = recentUploadsLogs;

    if (isFailed) {
      toast.error('Something went wrong in retrieving Recent Uploads. Please refresh the page');
    }

    if (isSuccess) {
      const newData = recentUploadsList?.data.map((dd) => {
        const videoID = dd?.relationships?.user_content_video?.data?.id;

        const videoData = recentUploadsList?.included.find(
          (dd) => dd?.type === 'user_content_video' && +dd?.id === +videoID,
        );

        return {
          data: dd,
          id: dd?.id,
          video: videoData,
          title: dd?.attributes?.title,
          thumb: dd?.attributes?.thumbnail,
          duration: dd?.attributes?.duration,
          category: dd?.attributes?.category,
          earnings: dd?.attributes?.earnings,
          description: dd?.attributes?.description,
          total_earnings: dd?.attributes?.total_earnings,
          streamTime: `${dd?.attributes?.total_stream} min${
            dd?.attributes?.total_stream > 1 ? 's' : ''
          }`,
        };
      });

      setTableData(newData);
    }
  }, [recentUploadsLogs]);
};

export default Content;
