import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';

import styles from './modal.module.scss';
import ApproveIcon from 'assets/images/approve.png';

const SuccessModal = (props) => {
  const { onHide } = props;

  return (
    <Modal
      {...props}
      size='md'
      centered
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header}>
        <Modal.Title id='account-created' className={styles?.title}>
          CONTENT UPDATED
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        <Image src={ApproveIcon} className={styles?.icon} />

        <p>
          Your content has been updated and under review. We will get back to you in 24 to 48 hours.
        </p>

        <p>NOTE: When updated video or trailer, It will take several minutes to reflect.</p>

        <Button className={styles?.close} onClick={onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
