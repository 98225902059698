/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

// Core & Vendor Packages
import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// Components
import Header from './components/Header';
import Content from './components/Content';
import { selectAuth } from 'reducers/authReducer';
import { updateProfileData } from 'reducers/authReducer';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';
import { getdashboardState, getTransanctionHistory } from 'reducers/dashboardReducer';

// Main component
const MyProfile = () => {
  const dispatch = useDispatch();

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const dashboardState = useSelector(getdashboardState);
  const { recentUploadsList } = dashboardState;

  getDashboardData(dispatch, userInfo);

  return (
    <PrivateLayout pageTitle='Payout Request'>
      <Container fluid>
        <Row>
          <Col>
            <Header userInfo={userInfo} recentUploadsList={recentUploadsList} />

            <Content userInfo={userInfo} />
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

const getDashboardData = (dispatch, userInfo) => {
  useEffect(() => {
    if (userInfo?.id) {
      dispatch(
        getTransanctionHistory({
          id: userInfo?.id,
          payload: {
            per_page: 5,
          },
        }),
      );
    }

    const handleGetData = () => {
      dispatch(updateProfileData());
    };

    handleGetData();
  }, [userInfo?.id]);
};

export default MyProfile;
