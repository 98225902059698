/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, FormControl, Image } from 'react-bootstrap';

import VideoJS from 'components/VideoJS';
import SuccessModal from './Successmodal';
import { selectAuth } from 'reducers/authReducer';
import AzureMediaPlayer from 'components/AzureMediaPlayer';
import ConditionalRender from 'components/ConditionalRender';
import { getContentData, getcontentState } from 'reducers/contentReducer';

import sampleAvatar from 'assets/images/avatar.jpg';
import sampleVideo from 'assets/videos/video.mp4';
import sampleThumbnail from 'assets/images/thumb1.png';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';
import { ReactComponent as ArrowLeftSVG } from 'assets/svg/arrow-left.svg';

import styles from './index.module.scss';

const Content = () => {
  const dispatch = useDispatch();

  const contentState = useSelector(getcontentState);
  const userState = useSelector(selectAuth);

  const { userInfo } = userState;
  const { singleData, singleContentLogs } = contentState;
  const { data, included } = singleData;

  const navigate = useNavigate();

  const params = useParams();

  const playerRef = useRef(null);

  const [showModal, setShowModal] = useState(false);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const videoOptions = (data) => {
    return {
      fluid: true,
      controls: true,
      autoplay: true,
      responsive: true,
      aspectRatio: '16:9',
      sources: [
        {
          src: data,
          type: 'application/dash+xml',
        },
      ],
    };
  };

  const handleBack = () => {
    navigate('/approved-content-list');
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  callContentData(params, dispatch);

  console.log('singleData', singleData);

  const userVideo = included?.find((dd) => dd.type === 'user_content_video');

  return (
    <>
      <div className={styles?.dashboard}>
        <div className={styles?.approvedContent}>
          <div className={styles?.header}>
            <h5>VIEW CONTENT</h5>

            <Button className={styles?.goBack} onClick={handleBack}>
              <ArrowLeftSVG />
              GO BACK
            </Button>
          </div>

          <ConditionalRender
            condition={singleContentLogs.isLoading}
            renderIf={
              <div className={styles?.loading}>
                <LoadingSVG />
              </div>
            }
            renderElse={
              <div className={styles?.content}>
                <Row>
                  <Col sm={7}>
                    <div className={styles?.uploadContainer}>
                      {userVideo && (
                        <VideoJS
                          className='dash-video'
                          onReady={handlePlayerReady}
                          options={videoOptions(userVideo?.attributes?.video_path_dash)}
                        />
                      )}

                      {/* 
                      <AzureMediaPlayer
                        thumbnail={data?.attributes?.thumbnail}
                        video={data?.attributes?.video_path_dash}
                      /> */}
                    </div>
                  </Col>
                  <Col>
                    <div className={styles?.detailsContainer}>
                      <h1>{data?.attributes?.title}</h1>

                      <FormControl
                        rows='10'
                        readOnly
                        as='textarea'
                        className={styles?.description}
                        value={data?.attributes?.description}
                      />

                      <div className={styles?.details}>
                        <p>
                          Duration: <span>{data?.attributes?.duration}</span>
                        </p>

                        <p>
                          Category: <span>{data?.attributes?.category}</span>
                        </p>

                        <p>
                          Content Creator:{' '}
                          <span>
                            <Image src={userInfo?.attributes?.profile_pic} alt='creator profile' />
                            {userInfo?.attributes?.full_name}
                          </span>
                        </p>

                        <p>
                          Submitted on: <span>{data?.attributes?.created_at}</span>
                        </p>
                      </div>

                      <div className={styles?.castDetails}>
                        <ul>
                          <p>Cast: </p>

                          {data?.attributes?.cast &&
                            data?.attributes?.cast.length > 0 &&
                            data?.attributes?.cast.map((dd) => {
                              let data;

                              if (typeof dd === 'string') {
                                data = JSON.parse(dd);
                              } else {
                                data = dd;
                              }

                              console.log('data', data);

                              return (
                                <li key={data?.id}>
                                  <div className={styles?.artistOption}>
                                    {/* {handleArtistImage(artist)} */}
                                    {<HandleArtistImage artist={data} />}

                                    <p>
                                      {data?.cast_name}
                                      <span>{data?.cast_role_name}</span>
                                    </p>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            }
          />
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </>
  );
};

const callContentData = (params, dispatch) => {
  useEffect(() => {
    if (params?.id) {
      dispatch(getContentData(params.id));
    }
  }, [params]);
};

const HandleArtistImage = ({ artist }) => {
  const handleReturn = () => {
    if (!artist) return;

    if (artist?.cast_picture && typeof artist?.cast_picture === 'string') {
      return <img src={artist?.cast_picture} />;
    }

    if (artist?.cast_picture) {
      const sampleURL = URL.createObjectURL(artist?.cast_picture);

      return <img src={sampleURL} />;
    }
  };

  return handleReturn();
};

export default Content;
