/* eslint-disable no-unused-vars */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useRef, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';

// Components
import VideoJS from 'components/VideoJS';
import { camelize } from 'utils/camelize';
import AzureMediaPlayer from 'components/AzureMediaPlayer';
import ConditionalRender from 'components/ConditionalRender';
import { handleUploadChange } from 'helpers/handleUploadChange';

// Assets
import '../Styles/VideoUpload.scss';
import sampleThumbnail from 'assets/images/thumb1.png';
import { ReactComponent as UploadSVG } from 'assets/svg/upload.svg';

// Main component
const VideoUpload = ({ name, setState, stateValue, videoMeta, ...rest }) => {
  const hiddenFileInput = useRef(null);
  const videoEl = useRef(null);

  const playerRef = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeVideo = (e) => {
    if (e.target.files.length === 0) return;

    if (e.target.files.length > 0) {
      toast.dismiss();
      toast.info('Please click the video to upload different file');

      videoMeta(e.target.files[0], name);
    }

    handleUploadChange(setState)(e);
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const showVideoPreview = () => {
    if (stateValue.length === 0) return null;

    if (stateValue[0] && typeof stateValue[0] === 'string') {
      // const videoData = stateValue[0];

      const videoOptions = {
        fluid: true,
        controls: true,
        autoplay: true,
        responsive: true,
        sources: [
          {
            src: stateValue[0],
            type: 'application/dash+xml',
          },
        ],
      };

      // return <AzureMediaPlayer autoPlay={true} thumbnail={sampleThumbnail} video={videoData} />;
      return <VideoJS options={videoOptions} className='dash-video' onReady={handlePlayerReady} />;
    }

    if (stateValue[0] && stateValue && typeof stateValue[0] !== 'string') {
      const videoURL = URL.createObjectURL(stateValue[0]);

      const videoOptions = {
        fluid: true,
        controls: true,
        autoplay: true,
        responsive: true,
        sources: [
          {
            src: videoURL,
            name: stateValue[0]?.name,
            type: stateValue[0]?.type,
          },
        ],
      };

      return <VideoJS options={videoOptions} className='dash-video' onReady={handlePlayerReady} />;
    }
  };

  return (
    <Form.Group className='video-upload'>
      {stateValue && (
        <>
          <ConditionalRender
            condition={stateValue.length > 0}
            renderIf={
              <Form.Label htmlFor={camelize(name)} className='video-preview'>
                <Button onClick={handleClick} className='change-video'>
                  Change Video
                </Button>

                {showVideoPreview()}
              </Form.Label>
            }
            renderElse={
              <Form.Label
                onClick={handleClick}
                htmlFor={camelize(name)}
                className='upload-video-preview'
              >
                <div className='center-data'>
                  <UploadSVG />
                  Upload Video
                </div>
              </Form.Label>
            }
          />

          <Form.Control
            {...rest}
            type='file'
            accept='video/*'
            ref={hiddenFileInput}
            name={camelize(name)}
            onChange={handleChangeVideo}
          />
        </>
      )}
    </Form.Group>
  );
};

export default VideoUpload;
