import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import styles from './index.module.scss';

const index = (props) => {
  return (
    <Modal
      {...props}
      size='md'
      centered
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header}>
        <Modal.Title id='account-created' className={styles?.title}>
          ACCOUNT RESET
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        <p>
          Congratulations! Your password has been changed.
        </p>

        <Button className={styles?.close} onClick={props.onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default index;
