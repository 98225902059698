import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';

import styles from './modal.module.scss';
import ApproveIcon from 'assets/images/approve.png';

const ConfirmModal = (props) => {
  const { onHide, title, description, data, handleConfirm, isShow, ...rest } = props;

  return (
    <Modal
      {...rest}
      size='md'
      centered
      show={isShow}
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header}>
        <Modal.Title id='account-created' className={styles?.title}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        <Image src={ApproveIcon} className={styles?.icon} />

        <p>{description}</p>

        <div className={styles?.buttons}>
          <Button className={styles?.confirm} onClick={() => handleConfirm(data)}>
            Confirm
          </Button>

          <Button className={styles?.close} onClick={onHide}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
