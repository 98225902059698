/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getTCs,
  getFAQs,
  getPayout,
  getRecentUploads,
  getTransanctionHistory,
} from './thunks/dashboardThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  // For Recent Uploads
  transactionHistoryList: [],
  transactionHistoryLogs: initialLog,

  // For Transaction History
  recentUploadsList: [],
  recentUploadsLogs: initialLog,

  // For FAQs List
  faqList: [],
  faqListLogs: initialLog,

  // For TCs List
  tcList: [],
  tcListLogs: initialLog,

  getPayoutList: [],
  getPayoutLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'dashboard',
  reducers: {
    resetState: (state, action) => {
      state.transactionHistoryList = [];
      state.recentUploadsList = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getTCs.fulfilled]: (state, action) => {
      state.tcList = action?.payload?.data;
      state.tcListLogs = {
        ...state.tcListLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getTCs.pending]: (state) => {
      state.tcListLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getTCs.rejected]: (state, action) => {
      state.tcListLogs = {
        ...state.tcListLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [getFAQs.fulfilled]: (state, action) => {
      state.faqList = action?.payload?.data;
      state.faqListLogs = {
        ...state.faqListLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getFAQs.pending]: (state) => {
      state.faqListLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getFAQs.rejected]: (state, action) => {
      state.faqListLogs = {
        ...state.faqListLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [getRecentUploads.fulfilled]: (state, action) => {
      state.recentUploadsList = action?.payload?.data;
      state.recentUploadsLogs = {
        ...state.recentUploadsLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getRecentUploads.pending]: (state) => {
      state.recentUploadsLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getRecentUploads.rejected]: (state, action) => {
      state.recentUploadsLogs = {
        ...state.recentUploadsLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [getTransanctionHistory.fulfilled]: (state, action) => {
      state.transactionHistoryList = action?.payload?.data;
      state.transactionHistoryLogs = {
        ...state.transactionHistoryLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getTransanctionHistory.pending]: (state) => {
      state.transactionHistoryLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getTransanctionHistory.rejected]: (state, action) => {
      state.transactionHistoryLogs = {
        ...state.transactionHistoryLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [getPayout.fulfilled]: (state, action) => {
      // console.log(action?.payload?.included);
      state.payoutData = action?.payload?.data;
      state.getPayoutList = action?.payload?.included;
      state.getPayoutLogs = {
        ...state.getPayoutLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getPayout.pending]: (state) => {
      state.getPayoutLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getPayout.rejected]: (state, action) => {
      state.getPayoutLogs = {
        ...state.getPayoutLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
  },
});

export const { actions: dashboardActions, reducer: dashboardReducer } = slice;

export const { resetState } = dashboardActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.dashboard || initialState;

export const getdashboardState = createSelector([selectDomain], (dashboardState) => dashboardState);

/* --------------------------------------------------------------------------------- */

export { getTCs, getFAQs, getPayout, getRecentUploads, getTransanctionHistory };
