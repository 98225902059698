/* eslint-disable newline-per-chained-call */

export const getSelectedDate = (daysAgo) => {
  const today = new Date();
  const daysAgoMilliseconds = daysAgo * 24 * 60 * 60 * 1000;
  const date = new Date(today.getTime() - daysAgoMilliseconds);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  return `${month} ${day}`;
};

export const getDateFromPreviousDate = (previousDays) => {
  // Get the current date
  const currentDate = new Date();

  // Subtract the specified number of days from the current date
  const previousDate = new Date(currentDate.setDate(currentDate.getDate() - previousDays));

  // Format the date as YYYY-MM-DD
  const year = previousDate.getFullYear();
  const month = (previousDate.getMonth() + 1).toString().padStart(2, '0');
  const day = previousDate.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};
