/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';

import { getMonthlyPayout } from 'reducers/profileReducer';

import styles from './Chart.module.scss';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const Chart = () => {
  const dispatch = useDispatch();

  const [yearlyData, setYearlyData] = useState([]);

  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Bank Payout Statistics',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const data = {
    labels: yearlyData.map((data) => data?.month),
    datasets: [
      {
        label: 'Maya',
        backgroundColor: '#02CB60',
        data: yearlyData.map((data) => data?.maya),
      },
      {
        label: 'Gcash',
        backgroundColor: '#559EFE',
        data: yearlyData.map((data) => data?.gcash),
      },
    ],
  };

  const asyncMap = async () => {
    const currentYear = new Date().getFullYear();

    const response = await dispatch(
      getMonthlyPayout({
        parameters: {
          year: currentYear,
        },
      }),
    );

    if (response?.meta?.requestStatus === 'fulfilled') {
      let unfilteredData = response?.payload?.data[0];

      unfilteredData.sort((a, b) => {
        const monthNames = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];

        return monthNames.indexOf(a.month) - monthNames.indexOf(b.month);
      });

      setYearlyData(unfilteredData);
    }
  };

  useEffect(() => {
    if (yearlyData.length === 0) {
      asyncMap();
    }
  }, [yearlyData]);

  useEffect(() => {
    console.log('yearlyData', yearlyData);
  }, [yearlyData]);

  return (
    <div className={styles?.bankStatistic}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default Chart;
