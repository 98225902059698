/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const submitPayout = createAsyncThunk(
  'submitPayout',
  async ({ parameters, id }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `api/content/payout/request/${id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

