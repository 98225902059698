/* eslint-disable prefer-const */
export const objectCleaner = (obj) => {
  const id = obj?.id;

  let newObj = obj;
  for (let prop in newObj) {
    if (newObj[prop] === '') {
      delete newObj[prop];
    }

    if (prop === 'id') {
      delete newObj[prop];
    }
  }

  return { id: id, parameters: newObj };
};