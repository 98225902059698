/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
/* eslint-disable multiline-comment-style */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useRef } from 'react';

import './index.scss';

const AzureMediaPlayer = ({ thumbnail, video, autoPlay = false }) => {
  const videoRef = useRef(null);

  const showVideo = () => {
    const videoElement = videoRef.current;

    // eslint-disable-next-line prefer-const
    let myPlayer = window?.amp(
      videoElement,
      {
        fluid: true,
        controls: true,
        poster: thumbnail,
        autoplay: autoPlay,
        techOrder: ['azureHtml5JS', 'flashSS', 'html5FairPlayHLS', 'silverlightSS', 'html5'],
      },
      // function () {
      //   console.log('Good to go!');

      //   this.addEventListener('ended', function () {
      //     console.log('Finished!');
      //   });
      // },
    );

    myPlayer.src([
      {
        src: video,
        type: 'application/dash+xml',
      },
    ]);
  };

  useEffect(() => {
    if (window?.amp && video && thumbnail) {
      showVideo();
    }
  }, [window, video, thumbnail]);

  return (
    <div data-vjs-player>
      <video
        tabIndex='0'
        ref={videoRef}
        id='videoContainer'
        className='azuremediaplayer amp-default-skin amp-big-play-centered'
      ></video>
    </div>
  );
};

export default AzureMediaPlayer;
