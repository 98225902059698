/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getCastList,
  deleteContent,
  submitContent,
  updateContent,
  getContentList,
  archiveContent,
  getContentData,
  unarchiveContent,
} from './thunks/contentThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  // For Content list
  contentList: [],
  contentLogs: initialLog,

  // For Content Single Data
  singleData: {},
  singleDataInclude: [],
  singleContentLogs: initialLog,

  // For Submit Content Logs
  submitContentLogs: initialLog,

  // For Update Content Logs
  updateContentLogs: initialLog,
};

const slice = createSlice({
  initialState,
  name: 'content',
  reducers: {
    resetState: (state, action) => {
      state.transactionHistoryList = [];
      state.contentList = [];
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [getContentList.fulfilled]: (state, action) => {
      state.contentList = action?.payload?.data;
      state.contentLogs = {
        ...state.contentLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getContentList.pending]: (state) => {
      state.contentLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getContentList.rejected]: (state, action) => {
      state.contentLogs = {
        ...state.contentLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [getContentData.fulfilled]: (state, action) => {
      state.singleData = action?.payload?.data;
      state.singleContentLogs = {
        ...state.singleContentLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [getContentData.pending]: (state) => {
      state.singleContentLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [getContentData.rejected]: (state, action) => {
      state.singleContentLogs = {
        ...state.singleContentLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [submitContent.fulfilled]: (state, action) => {
      state.submitContentLogs = {
        ...state.submitContentLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [submitContent.pending]: (state) => {
      state.submitContentLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [submitContent.rejected]: (state, action) => {
      state.submitContentLogs = {
        ...state.submitContentLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    [updateContent.fulfilled]: (state, action) => {
      state.updateContentLogs = {
        ...state.updateContentLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [updateContent.pending]: (state) => {
      state.updateContentLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [updateContent.rejected]: (state, action) => {
      state.updateContentLogs = {
        ...state.updateContentLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
  },
});

export const { actions: contentActions, reducer: contentReducer } = slice;

export const { resetState } = contentActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.content || initialState;

export const getcontentState = createSelector([selectDomain], (contentState) => contentState);

/* --------------------------------------------------------------------------------- */

export {
  getCastList,
  updateContent,
  submitContent,
  deleteContent,
  getContentList,
  getContentData,
  archiveContent,
  unarchiveContent,
};
