/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Image, Form, FormControl, Button } from 'react-bootstrap';

import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUIs/FormText';
import { updateUserinfo } from 'reducers/authReducer';
import { objectCleaner } from 'helpers/objectCleaner';
import { updateProfile, selectprofile } from 'reducers/profileReducer';
import { ReactComponent as LoadingSVG } from 'assets/svg/button_loading.svg';

import styles from './ChannelAndProfile.module.scss';
import { toast } from 'react-toastify';

const initialState = {
  id: '',
  email: '',
  handle: '',
  lastName: '',
  firstName: '',
  channelURL: '',
  phoneNumber: '',
};

const ChannelAndProfile = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userState = useSelector(selectAuth);
  const profileState = useSelector(selectprofile);
  const { userInfo } = userState;
  const { updateProfileLogs } = profileState;

  const [formData, setFormData] = useState(initialState);

  const handleCancel = () => {
    navigate('/my-profile');
  };

  const handleSave = async () => {
    // api here the data is inside formData
    const params = {
      // username: '',
      id: formData?.id,
      email: formData?.email,
      last_name: formData?.lastName,
      first_name: formData?.firstName,
      mobile_number: formData?.phoneNumber,
    };

    const { parameters, id } = objectCleaner(params);

    const response = await dispatch(updateProfile({ parameters: params, id: userInfo?.id }));

    if (response?.meta?.requestStatus === 'fulfilled') {
      toast.success('Successfully updated the profile details.');

      dispatch(updateUserinfo(response.payload?.data?.data));
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const firstNameProps = {
    type: 'text',
    isUnderscore: true,
    name: 'first name',
    setState: setFormData,
    placeholder: 'Enter Here...',
    haveClass: styles?.borderText,
    stateValue: formData?.firstName,
  };

  const lastNameProps = {
    type: 'text',
    name: 'last name',
    setState: setFormData,
    placeholder: 'Enter Here...',
    haveClass: styles?.borderText,
    stateValue: formData?.lastName,
  };

  const handleProps = {
    type: 'text',
    name: 'handle',
    setState: setFormData,
    placeholder: 'Enter Here...',
    haveClass: styles?.borderText,
    stateValue: formData?.handle,
  };

  const emailProps = {
    type: 'email',
    name: 'Email',
    setState: setFormData,
    placeholder: 'Enter Here...',
    haveClass: styles?.borderText,
    stateValue: formData?.email,
  };

  const phoneNumberProps = {
    type: 'number',
    name: 'Phone number',
    setState: setFormData,
    placeholder: 'Enter Here...',
    haveClass: styles?.borderText,
    stateValue: formData?.phoneNumber,
  };

  const channelURLProps = {
    type: 'text',
    name: 'Channel URL',
    setState: setFormData,
    placeholder: 'Enter Here...',
    haveClass: styles?.borderText,
    stateValue: formData?.channelURL,
  };

  const saveButtonProps = {
    onClick: handleSave,
    className: styles?.save,
    disabled: updateProfileLogs?.isLoading,
  };

  const cancelButtonProps = {
    onClick: handleCancel,
    className: styles?.cancel,
  };

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  setUserInfos(userInfo, setFormData);

  return (
    <div className={styles?.channelContainer}>
      <h4>Channel and Profile Customization</h4>

      <p>PERSONAL DETAILS</p>

      <div className={styles?.formContainer}>
        <FormText {...firstNameProps} />
        <FormText {...lastNameProps} />
        <FormText {...handleProps} />
      </div>

      <div className={styles?.formContainer}>
        <FormText {...emailProps} />
        <FormText {...phoneNumberProps} />
        <FormText {...channelURLProps} />
      </div>

      <div className={styles?.actionButtons}>
        {updateProfileLogs?.isLoading && <LoadingSVG className={styles?.loading} />}

        <Button {...saveButtonProps}>Save</Button>
        <Button {...cancelButtonProps}>Cancel</Button>
      </div>
    </div>
  );
};

const setUserInfos = (userInfo, setFormData) => {
  useEffect(() => {
    if (userInfo?.id) {
      setFormData((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
          email: userInfo?.attributes?.email,
          lastName: userInfo?.attributes?.last_name,
          firstName: userInfo?.attributes?.first_name,
          phoneNumber: userInfo?.attributes?.mobile_number,
        };
      });
    }
  }, [userInfo]);
};

export default ChannelAndProfile;
