import { Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import {
  Title,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';

import { getWatchedTime } from 'reducers/profileReducer';
import { getSelectedDate, getDateFromPreviousDate } from 'helpers/getSelectedDate';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WatchedTime = () => {
  const dispatch = useDispatch();

  const [watchedTime, setWatchedTime] = useState([
    {
      date: getSelectedDate(6),
      dateFormat: getDateFromPreviousDate(6),
      value: '',
    },
    {
      date: getSelectedDate(5),
      dateFormat: getDateFromPreviousDate(5),
      value: '',
    },
    {
      date: getSelectedDate(4),
      dateFormat: getDateFromPreviousDate(4),
      value: '',
    },
    {
      date: getSelectedDate(3),
      dateFormat: getDateFromPreviousDate(3),
      value: '',
    },
    {
      date: getSelectedDate(2),
      dateFormat: getDateFromPreviousDate(2),
      value: '',
    },
    {
      date: getSelectedDate(1),
      dateFormat: getDateFromPreviousDate(1),
      value: '',
    },
    {
      date: getSelectedDate(0),
      dateFormat: getDateFromPreviousDate(0),
      value: '',
    },
  ]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Watch Time (Hours) Since Last Week',
      },
    },
  };

  const labels = watchedTime.map((data) => data?.date);

  const data = {
    labels,
    datasets: [
      {
        label: 'Views',
        borderColor: '#038F45',
        backgroundColor: '#02CB60',
        data: watchedTime.map((data) => data?.value),
      },
    ],
  };

  const asyncMap = async () => {
    const result = [];

    for (let i = 0; i < watchedTime.length; i++) {
      const modifiedElement = await getWeeklyDataByDate(watchedTime[i]?.dateFormat);

      result.push({
        value: modifiedElement,
        date: watchedTime[i]?.date,
      });
    }

    setWatchedTime(result);
  };

  const getWeeklyDataByDate = async (date) => {
    const result = await dispatch(
      getWatchedTime({
        parameters: {
          day: date,
        },
      }),
    );

    if (result?.meta?.requestStatus === 'fulfilled') {
      return result?.payload?.data[0];
    }

    return 'Error';
  };

  useEffect(() => {
    console.log('watchedTime', watchedTime);

    if (watchedTime.length > 0 && watchedTime[0].value === '') {
      asyncMap();
    }
  }, [watchedTime]);

  return <Line options={options} data={data} />;
};

export default WatchedTime;
