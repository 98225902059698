/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars

import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Image, Button, Form } from 'react-bootstrap';

import VideoJS from 'components/VideoJS';
import { objectCleaner } from 'helpers/objectCleaner';
import AzureMediaPlayer from 'components/AzureMediaPlayer';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getRecentUploads, getdashboardState } from 'reducers/dashboardReducer';

import styles from './Content.module.scss';
import gcashSample from 'assets/images/gcash.png';
import paymayaSample from 'assets/images/paymaya.png';
import { ReactComponent as SortSVG } from 'assets/svg/sort.svg';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';

const Content = ({ userInfo }) => {
  const dispatch = useDispatch();

  const playerRef = useRef(null);

  const dashboardState = useSelector(getdashboardState);
  const { transactionHistoryList, recentUploadsList, transactionHistoryLogs, recentUploadsLogs } =
    dashboardState;

  const [transactionHistory, setTransactionHistory] = useState([]);

  const [params, setParams] = useState({
    q: '',
    id: '',
    page: 1,
    per_page: 5,
    order_by: '',
    filter_status: '',
    include: ['user_content_video'],
  });

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const setVideoOptions = (data) => {
    return {
      fluid: true,
      controls: true,
      autoplay: false,
      responsive: true,
      aspectRatio: '16:9',
      poster: data?.thumbnail,
      sources: [
        {
          src: data?.video_path_dash,
          type: 'application/dash+xml',
          keySystemOptions: [
            {
              name: 'com.widevine.alpha',
              options: {
                serverURL: 'http://m.widevine.com/proxy',
              },
            },
          ],
        },
      ],
    };
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: params?.q,
      include: params?.include,
      order_by: params?.order_by,
      filter_status: params?.filter_status,
    };

    const { parameters } = objectCleaner(newObj);

    backToTop();

    dispatch(
      getRecentUploads({
        id: params?.id,
        parameters: parameters,
      }),
    );
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;

    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    const newObj = {
      per_page: 5,
      q: params?.q,
      page: params?.page,
      filter_status: value,
      include: params?.include,
      order_by: params?.order_by,
    };

    const { parameters } = objectCleaner(newObj);

    dispatch(
      getRecentUploads({
        id: params?.id,
        parameters: parameters,
      }),
    );
  };

  const backToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  getContentList(dispatch, userInfo, params, setParams);

  getTransactionHistoryState(transactionHistoryLogs, setTransactionHistory, transactionHistoryList);

  return (
    <div className={styles?.content}>
      <Row>
        <Col xs={3}>
          <div className={styles?.historyContainer}>
            <div className={styles?.header}>
              <p>Payout History</p>

              <SortSVG className={styles?.sort} />
            </div>

            <div className={styles?.transactionList}>
              <ul>
                {transactionHistory &&
                  transactionHistory.length > 0 &&
                  transactionHistory.map((data) => {
                    return (
                      <li key={data?.id} className={styles?.list}>
                        <div className={styles?.leftContainer}>
                          <Image src={data?.img} />
                        </div>
                        <div className={styles?.rightContainer}>
                          <p>
                            {data?.detail} <span>{data?.date}</span>
                          </p>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Col>
        <Col xs={9}>
          <div className={styles?.filterContainer}>
            <p>FILTER CONTENTS</p>

            <Form.Select
              value={params?.filter_status}
              name='filter_status'
              onChange={handleChangeFilter}
            >
              <option hidden value=''>
                <SortSVG className={styles?.sort} />
              </option>
              <option value=''>All</option>
              <option value='pending'>Pending</option>
              <option value='approved'>Approved</option>
              <option value='declined'>Declined</option>
            </Form.Select>
          </div>

          <ConditionalRender
            condition={recentUploadsLogs?.isLoading}
            renderIf={
              <div className={styles?.loading}>
                <LoadingSVG />
              </div>
            }
            renderElse={
              recentUploadsList?.data &&
              recentUploadsList.included &&
              recentUploadsList?.data.length > 0 &&
              recentUploadsList?.data.map((data, idx) => {
                const contentId = data?.relationships?.user_content_video?.data?.id;
                const video = recentUploadsList.included.find((d) => +d?.id === +contentId);

                return (
                  <div key={idx} className={styles?.mainContent}>
                    <div className={styles?.header}>
                      <div className={styles?.userInfo}>
                        <Image src={data?.attributes?.creator_pic} />

                        <p>
                          {data?.attributes?.creator_name}{' '}
                          <span>{data?.attributes?.created_at}</span>
                        </p>
                      </div>

                      <Button
                        className={`${styles?.button} ${styles[data?.attributes?.content_status]}`}
                      >
                        {data?.attributes?.content_status}
                      </Button>
                    </div>

                    <div className={styles?.details}>
                      <div className={styles?.videoContainer}>
                        {/* <VideoJS
                          onReady={handlePlayerReady}
                          options={setVideoOptions(data?.attributes)}
                        /> */}

                        <AzureMediaPlayer
                          thumbnail={data?.attributes?.thumbnail}
                          video={video?.attributes?.video_path_dash}
                        />
                      </div>

                      <p className={styles?.description}>
                        <b>Description:</b>
                        <span>{data?.attributes?.description}</span>
                      </p>

                      <b>{data?.attributes?.duration}</b>
                    </div>
                  </div>
                );
              })
            }
          />

          <div className={styles?.mainContent}>
            <TablePagination
              setParams={setParams}
              data={recentUploadsList}
              loading={recentUploadsLogs?.isLoading}
              handlePaginationClick={handlePaginationClick}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

const getContentList = (dispatch, userInfo, params, setParams) => {
  useEffect(() => {
    if (userInfo?.id) {
      setParams((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
        };
      });

      const newObj = {
        per_page: 5,
        q: params?.q,
        filter_status: '',
        page: params?.page,
        include: params?.include,
        order_by: params?.order_by,
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(getRecentUploads({ id: userInfo?.id, parameters: parameters }));
    }
  }, [userInfo?.id]);
};

const getTransactionHistoryState = (
  setTransactionHistory,
  transactionHistoryLogs,
  transactionHistoryList,
) => {
  useEffect(() => {
    const { isFailed, isSuccess, type } = transactionHistoryLogs;

    if (isFailed) {
      toast.error(
        'Something went wrong in retrieving Transaction History. Please refresh the page',
      );
    }

    if (isSuccess) {
      const newData = transactionHistoryList?.data.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          detail: dd?.attributes?.amount,
          date: dd?.attributes?.created_at,
          img: getBankImage(dd?.attributes?.payment_channel),
        };
      });

      setTransactionHistory(newData);
    }
  }, [transactionHistoryLogs]);
};

const getBankImage = (type) => {
  switch (type) {
    case 'gcash':
      return gcashSample;
    case 'maya':
      return paymayaSample;
    default:
      break;
  }
};

export default Content;
