/* eslint-disable */
import _, { initial } from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getPayout,
  submitPayout,
} from './thunks/payoutThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  // For Submit Content Logs
  submitPayoutLogs: initialLog,

  // For get Payout
  payoutData: [],
  payoutIncluded: [],
};

const slice = createSlice({
  initialState,
  name: 'payout',
  reducers: {
    resetState: (state, action) => {
      state.logs = initialLog;
    },
  },
  extraReducers: {
    [submitPayout.fulfilled]: (state, action) => {
      state.submitPayoutLogs = {
        ...state.submitPayoutLogs,
        isSuccess: true,
        isLoading: false,
      };
    },
    [submitPayout.pending]: (state) => {
      state.submitPayoutLogs = {
        error: '',
        isFailed: false,
        isSuccess: false,
        isLoading: true,
      };
    },
    [submitPayout.rejected]: (state, action) => {
      state.submitPayoutLogs = {
        ...state.submitPayoutLogs,
        isFailed: true,
        isLoading: false,
        error: action?.payload?.errors[0]?.detail,
      };
    },
    
  },
});

export const { actions: payoutActions, reducer: payoutReducer } = slice;

export const { resetState } = payoutActions;
/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.payout || initialState;

export const getpayoutState = createSelector([selectDomain], (payoutState) => payoutState);

/* --------------------------------------------------------------------------------- */

export {
  submitPayout,
};
