// Import core & vendor packages below
import { useRef } from 'react';
import { Form } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import ConditionalRender from 'components/ConditionalRender';
import { handleUploadChange } from 'helpers/handleUploadChange';

// Main component
const FormUpload = ({ name, text, setState, stateValue, haveClass = '', ...rest }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return (
    <Form.Group className={`${haveClass} global-form-input`}>
      <ConditionalRender
        condition={stateValue.length > 0}
        renderIf={
          <Form.Label onClick={handleClick} htmlFor={camelize(name)}>
            {stateValue[0]?.name}
          </Form.Label>
        }
        renderElse={
          <Form.Label onClick={handleClick} htmlFor={camelize(name)}>
            {text}
          </Form.Label>
        }
      />

      <Form.Control
        {...rest}
        type='file'
        ref={hiddenFileInput}
        name={camelize(name)}
        onChange={handleUploadChange(setState)}
        accept='image/png, image/gif, image/jpeg'
      />
    </Form.Group>
  );
};

export default FormUpload;
