/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import React, { useState } from 'react';
import { Navbar, Container, Offcanvas, Nav, NavDropdown, Form, Button } from 'react-bootstrap';

// Components
import ReactHelmet from './ReactHelmet';
import NavBar from 'components/Layouts/NavBar';

// Assets
import 'components/Styles/PrivateLayout.scss';

const PrivateLayout = ({ children, pageTitle }) => {
  return (
    <>
      <ReactHelmet title={pageTitle} />

      <div className='navigation-bar'>
        <div className='right-container'>
          <NavBar />

          <div className='main-container'>{children}</div>
        </div>
      </div>
    </>
  );
};

export default PrivateLayout;
