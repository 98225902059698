/* eslint-disable no-unused-vars */
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Image, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';

import TCModal from './TCModal';
import PasswordModal from './Modal';
import { updateUserinfo } from 'reducers/authReducer';
import { updateProfile } from 'reducers/profileReducer';

import styles from './header.module.scss';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import { ReactComponent as PhoneSVG } from 'assets/svg/phone.svg';
import { ReactComponent as GlobeSVG } from 'assets/svg/globe.svg';
import { ReactComponent as VideoSVG } from 'assets/svg/video-2.svg';
import { ReactComponent as EmailSVG } from 'assets/svg/envelope.svg';

const Header = ({ userInfo, recentUploadsList }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [modalConfig, setModalConfig] = useState({
    title: '',
    data: null,
    onHide: null,
    isShow: false,
    description: '',
    handleConfirm: null,
  });

  const [tcModalConfig, setTCModalConfig] = useState({
    isShow: false,
    body: '',
  });

  const confirmChangePassword = async (data) => {
    const formData = new FormData();

    formData.append('password', data?.password);
    formData.append('current_password', data?.currentPassword);
    formData.append('password_confirmation', data?.confirmPassword);

    const response = await dispatch(
      updateProfile({
        id: userInfo?.id,
        parameters: formData,
      }),
    );

    if (response?.meta?.requestStatus === 'fulfilled') {
      closeModal();

      toast.success('Successfully updated password');

      dispatch(updateUserinfo(response.payload?.data?.data));
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const closeModal = () => {
    setModalConfig({
      title: '',
      data: null,
      onHide: null,
      isShow: false,
      description: '',
      handleConfirm: null,
    });
  };

  const handleChangePassword = () => {
    setModalConfig({
      isShow: true,
      data: userInfo,
      onHide: closeModal,
      handleConfirm: confirmChangePassword,
      title: 'Change Password',
    });
  };

  const closeTC = () => {
    setTCModalConfig((prev) => {
      return {
        ...prev,
        isShow: false,
      };
    });
  };

  const handleTC = () => {
    setTCModalConfig((prev) => {
      return {
        ...prev,
        isShow: true,
        onHide: closeTC,
      };
    });
  };

  const handleCustomizeChannael = () => {
    navigate('/my-profile/edit');
  };

  return (
    <div
      className={styles?.header}
      style={{ background: `url('${userInfo?.attributes?.profile_banner}')` }}
    >
      <div className={styles?.innerHeader}>
        <div className={styles?.profile}>
          <div className={styles?.imageContainer}>
            <Image
              className={styles?.avatar}
              src={userInfo?.attributes?.profile_pic}
              alt='avatar'
            />
          </div>

          <div className={styles?.name}>
            <p>
              {userInfo?.attributes?.full_name} <CheckSVG className={styles?.check} />
            </p>

            <span>{userInfo?.attributes?.username}</span>
          </div>
        </div>

        <div className={styles?.details}>
          <p>
            <b>
              <EmailSVG /> EMAIL
            </b>
            <span>{userInfo?.attributes?.email}</span>
          </p>
          <p>
            <b>
              <PhoneSVG /> PHONE
            </b>
            <span>{userInfo?.attributes?.mobile_number}</span>
          </p>
          <p>
            <b>
              <VideoSVG /> TOTAL VIDEOS
            </b>
            <span>{recentUploadsList?.meta?.pagination?.total}</span>
          </p>
          <p className={styles?.long}>
            <b>
              <VideoSVG /> TOTAL VIEW COUNT
            </b>
            <span>{userInfo?.attributes?.total_content_views}</span>
          </p>
          <p>
            <b>
              <GlobeSVG />
              COUNTRY
            </b>
            <span>Philippines</span>
          </p>
        </div>

        <div className={styles?.actions}>
          <Button onClick={handleCustomizeChannael} className={styles?.customize}>
            Customize Channel
          </Button>
          <Button onClick={handleChangePassword} className={styles?.password}>
            Change Password
          </Button>
          <Button onClick={handleTC} className={styles?.tc}>
            Terms and Conditions
          </Button>
        </div>
      </div>

      <PasswordModal {...modalConfig} />

      <TCModal {...tcModalConfig} />
    </div>
  );
};

export default Header;
