/* eslint-disable camelcase */
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Table, Button, Popover, FormControl, OverlayTrigger } from 'react-bootstrap';

import { selectAuth } from 'reducers/authReducer';
import { getContentStatus } from 'helpers/getStatus';
import { objectCleaner } from 'helpers/objectCleaner';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getContentList, getcontentState } from 'reducers/contentReducer';

import styles from './index.module.scss';
import PlusIcon from 'assets/images/plus.png';
import actionIcon from 'assets/images/action.png';
import { ReactComponent as SearchSVG } from 'assets/svg/Search.svg';
import { ReactComponent as FilterSVG } from 'assets/svg/filter.svg';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';

const Content = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const contentState = useSelector(getcontentState);
  const userState = useSelector(selectAuth);

  const { userInfo } = userState;
  const { contentList, contentLogs } = contentState;

  const [params, setParams] = useState({
    q: '',
    id: '',
    page: 1,
    per_page: 5,
    filter_status: '',
  });

  const handleAddContent = () => {
    navigate('/content-submission/add-content');
  };

  const handleSearch = () => {
    setParams((prev) => {
      return {
        ...prev,
        page: 1,
      };
    });

    const newObj = {
      q: params?.q,
      per_page: 5,
      page: 1,
      filter_status: params?.filter_status,
    };

    const { parameters } = objectCleaner(newObj);

    dispatch(
      getContentList({
        parameters: parameters,
        id: params?.id,
      }),
    );
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      q: params?.q,
      page: page,
      per_page: 5,
      filter_status: params?.filter_status,
    };

    const { parameters } = objectCleaner(newObj);

    console.log('handlePaginationClick...');

    dispatch(
      getContentList({
        parameters: parameters,
        id: params?.id,
      }),
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleView = (data) => {
    navigate(`/content-submission/${data?.id}`);
  };

  setUserInfo(userInfo, params, setParams);

  useEffect(() => {
    console.log('params', params);
  }, [params]);

  return (
    <div className={styles?.dashboard}>
      <div className={styles?.recentUploads}>
        <div className={styles?.header}>
          <h5>CONTENT SUBMISSION LIST</h5>

          <div className={styles?.filters}>
            <Button className={styles?.addContent} onClick={handleAddContent}>
              <Image src={PlusIcon} /> Add Content
            </Button>

            <Form.Group className={styles?.search}>
              <SearchSVG className={styles?.searchIcon} />

              <FormControl
                name='q'
                value={params?.q}
                onChange={handleChange}
                placeholder='Search...'
              />
            </Form.Group>

            <Form.Group className={styles?.filter}>
              <FilterSVG className={styles?.filterIcon} />

              <Form.Select
                name='filter_status'
                value={params?.filter_status}
                onChange={handleChange}
              >
                <option value='' hidden>
                  Select...
                </option>
                <option value='pending'>Pending</option>
                <option value='approved'>Approved</option>
                <option value='declined'>Declined</option>
              </Form.Select>
            </Form.Group>

            <Button className={styles?.searchButton} onClick={handleSearch}>
              Search
            </Button>
          </div>
        </div>

        <Table responsive className={styles?.UploadTable}>
          <thead>
            <tr>
              <th>THUMBNAIL</th>
              <th>VIDEO TITLE</th>
              <th>DESCRIPTION</th>
              <th>DURATION</th>
              <th>CATEGORY</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ConditionalRender
              condition={contentLogs?.isLoading}
              renderIf={
                <tr>
                  <td colSpan={7} className={styles?.loading}>
                    <LoadingSVG />
                  </td>
                </tr>
              }
              renderElse={
                contentList?.data &&
                contentList?.data.length > 0 &&
                contentList?.data.map((data) => {
                  const status = data?.attributes?.content_status;
                  const canEdit = status === 'pending' || status === 'declined';

                  return (
                    <tr key={data?.id}>
                      <td>
                        <Image className={styles?.thumnbnail} src={data?.attributes?.thumbnail} />
                      </td>
                      <td>{data?.attributes?.title}</td>
                      <td>{data?.attributes?.description}</td>
                      <td>{data?.attributes?.duration}</td>
                      <td>{data?.attributes?.category}</td>
                      <td>{getContentStatus(status)}</td>
                      <td>
                        <OverlayTrigger
                          trigger='click'
                          placement='bottom'
                          overlay={
                            <Popover id='TableAction'>
                              <Popover.Body className='d-flex pop-up-cont justify-content-between p-0'>
                                <Button
                                  onClick={() => handleView(data)}
                                  disabled={canEdit === false}
                                  className={`view-data ${
                                    canEdit === false ? 'button-disabled' : ''
                                  }`}
                                >
                                  Edit
                                </Button>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Button
                            variant='secondary'
                            className='popoverButton bg-transparent p-0 border-0'
                          >
                            <Image src={actionIcon} className={styles?.action} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })
              }
            />
          </tbody>
        </Table>

        <TablePagination
          data={contentList}
          setParams={setParams}
          loading={contentLogs?.isLoading}
          handlePaginationClick={handlePaginationClick}
        />
      </div>
    </div>
  );
};

const setUserInfo = (userInfo, params, setParams) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.id) {
      setParams((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
        };
      });

      const newObj = {
        per_page: 5,
        q: params?.q,
        page: params?.page,
        filter_status: params?.filter_status,
      };

      const { parameters } = objectCleaner(newObj);

      console.log('setUserInfo...');

      dispatch(
        getContentList({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    }
  }, [userInfo?.id]);
};

export default Content;
