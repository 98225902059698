/* eslint-disable no-unused-vars */
// Import core & vendor packages below
import { v4 as uuidv4 } from 'uuid';
import { useRef, useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Form, Image, FormControl } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import ConditionalRender from 'components/ConditionalRender';

// Assets
import '../Styles/FileUploads.scss';
import { ReactComponent as AddSVG } from 'assets/svg/circle-plus.svg';
import { ReactComponent as RemoveSVG } from 'assets/svg/circle-minus.svg';

// Main component
const FileUploads = ({ name, setState, stateValue, label = '', accept = '', ...rest }) => {
  const handleFileChange = (data) => (e) => {
    const file = e.target.files;

    const newData = stateValue.map((d) => {
      if (d?.id === data?.id) {
        return {
          ...d,
          file: file,
        };
      }

      return d;
    });

    setState((prev) => {
      return {
        ...prev,
        [name]: newData,
      };
    });
  };

  const handleLanguageChange = (data) => (e) => {
    const newData = stateValue.map((d) => {
      if (d?.id === data?.id) {
        return {
          ...d,
          language: e,
        };
      }

      return d;
    });

    setState((prev) => {
      return {
        ...prev,
        [name]: newData,
      };
    });
  };

  const handleAdd = () => {
    const newData = [
      ...stateValue,
      {
        file: null,
        id: uuidv4(),
        language: { label: '', value: '' },
      },
    ];

    setState((prev) => {
      return {
        ...prev,
        [name]: newData,
      };
    });
  };

  const handleRemove = (data) => {
    const newData = stateValue.filter((d) => d?.id !== data?.id);

    setState((prev) => {
      return {
        ...prev,
        [name]: newData,
      };
    });
  };

  const defaultOptions = [
    { label: 'English', value: 'English' },
    { label: 'Tagalog', value: 'Tagalog' },
  ];

  return (
    <Form.Group className='file-upload'>
      {stateValue &&
        stateValue.length > 0 &&
        stateValue.map((data, idx) => {
          return (
            <div className='subtitleDetails' key={data?.id}>
              <CreatableSelect
                isClearable
                value={data?.language}
                options={defaultOptions}
                className='language-selection grey-bg'
                onChange={(e) => handleLanguageChange(data)(e)}
              />

              {data?.file && typeof data?.file[0] === 'string' ? (
                <Form.Label htmlFor={camelize(`${name} ${idx}`)} className='upload-file-preview'>
                  {`${data?.language?.value || 'N/A'} version`}
                </Form.Label>
              ) : (
                <Form.Label htmlFor={camelize(`${name} ${idx}`)} className='upload-file-preview'>
                  {data?.file
                    ? `${data?.file[0]?.name.substring(0, 15)}...`
                    : `Select ${label} file`}
                </Form.Label>
              )}

              <FormControl
                {...rest}
                type='file'
                accept={accept}
                className='hidden-file'
                id={camelize(`${name} ${idx}`)}
                name={camelize(`${name} ${idx}`)}
                onChange={(e) => handleFileChange(data)(e)}
              />

              <AddSVG className='add-button' onClick={handleAdd} />

              {stateValue.length > 1 && (
                <RemoveSVG className='remove-button' onClick={() => handleRemove(data)} />
              )}
            </div>
          );
        })}
    </Form.Group>
  );
};

export default FileUploads;
