/* eslint-disable multiline-comment-style */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { Form, Image } from 'react-bootstrap';

// Components
import { camelize } from 'utils/camelize';
import ConditionalRender from 'components/ConditionalRender';

// Assets
import '../Styles/CastImageUpload.scss';
import { ReactComponent as UploadSVG } from 'assets/svg/upload.svg';

// Main component
const CastImageUpload = ({ data, setFormData, formData, ...rest }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeCastAvatar = (e) => {
    if (e.target.files.length > 0) {
      toast.dismiss();
      toast.info('Please click the picture to upload different file');
    }

    const imgData = e.target.files[0];

    const newCastData = formData?.cast.map((d) => {
      if (d?.id === data?.id) {
        return {
          ...d,
          cast_picture: imgData,
        };
      }

      return d;
    });

    console.log('newCastData', newCastData);

    setFormData((prev) => {
      return {
        ...prev,
        cast: newCastData,
      };
    });
  };

  const showVideoPreview = () => {
    if (!data?.cast_picture) return;

    const sampleURL = URL.createObjectURL(data?.cast_picture);

    console.log('sampleURL', sampleURL);

    return <Image src={sampleURL} />;
  };

  return (
    <Form.Group className='cast-image-upload'>
      <ConditionalRender
        condition={data?.cast_picture}
        renderIf={
          <Form.Label onClick={handleClick} htmlFor={camelize(name)} className='cast-image-preview'>
            {showVideoPreview()}
          </Form.Label>
        }
        renderElse={
          <Form.Label
            onClick={handleClick}
            htmlFor={camelize(name)}
            className='upload-cast-image-preview'
          >
            <div className='center-data'>
              <UploadSVG />
              Upload Image
            </div>
          </Form.Label>
        }
      />

      <Form.Control
        {...rest}
        type='file'
        ref={hiddenFileInput}
        name={camelize(name)}
        onChange={handleChangeCastAvatar}
        accept='image/png, image/gif, image/jpeg'
      />
    </Form.Group>
  );
};

export default CastImageUpload;
