/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getRecentUploads = createAsyncThunk(
  'getRecentUploads',
  async ({ id, parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: parameters,
      url: `api/content/${id}/view`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getFAQs = createAsyncThunk('getFAQs', async (parameters, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: parameters,
    url: `api/content/faq/list?include[]=faqs_video`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getTCs = createAsyncThunk('getTCs', async (parameters, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: parameters,
    url: `api/policy/list`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});

export const getTransanctionHistory = createAsyncThunk(
  'getTransanctionHistory',
  async (params, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      params: params?.payload,
      url: `api/content/payouts/${params?.id}`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getPayout = createAsyncThunk('getPayout', async (params, { rejectWithValue }) => {
  return await axios({
    method: 'get',
    params: params?.payload,
    url: `api/profile?include[]=user_balance`,
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return rejectWithValue(error.response.data);
    });
});
