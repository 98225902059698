import { Line } from 'react-chartjs-2';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import {
  Title,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS,
} from 'chart.js';

import { getWeeklySubs } from 'reducers/profileReducer';
import { getSelectedDate, getDateFromPreviousDate } from 'helpers/getSelectedDate';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WeeklySubs = () => {
  const dispatch = useDispatch();

  const [weeklySubs, setWeeklySubs] = useState([
    {
      date: getSelectedDate(6),
      dateFormat: getDateFromPreviousDate(6),
      value: '',
    },
    {
      date: getSelectedDate(5),
      dateFormat: getDateFromPreviousDate(5),
      value: '',
    },
    {
      date: getSelectedDate(4),
      dateFormat: getDateFromPreviousDate(4),
      value: '',
    },
    {
      date: getSelectedDate(3),
      dateFormat: getDateFromPreviousDate(3),
      value: '',
    },
    {
      date: getSelectedDate(2),
      dateFormat: getDateFromPreviousDate(2),
      value: '',
    },
    {
      date: getSelectedDate(1),
      dateFormat: getDateFromPreviousDate(1),
      value: '',
    },
    {
      date: getSelectedDate(0),
      dateFormat: getDateFromPreviousDate(0),
      value: '',
    },
  ]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Subscribers Added Since Last Week',
      },
    },
  };

  const labels = weeklySubs.map((data) => data?.date);

  const data = {
    labels,
    datasets: [
      {
        label: 'Views',
        borderColor: '#038F45',
        backgroundColor: '#02CB60',
        data: weeklySubs.map((data) => data?.value),
      },
    ],
  };

  const asyncMap = async () => {
    const result = [];

    for (let i = 0; i < weeklySubs.length; i++) {
      const modifiedElement = await getWeeklyDataByDate(weeklySubs[i]?.dateFormat);

      result.push({
        value: modifiedElement,
        date: weeklySubs[i]?.date,
      });
    }

    setWeeklySubs(result);
  };

  const getWeeklyDataByDate = async (date) => {
    const result = await dispatch(
      getWeeklySubs({
        parameters: {
          day: date,
        },
      }),
    );

    if (result?.meta?.requestStatus === 'fulfilled') {
      return result?.payload?.data[0];
    }

    return 'Error';
  };

  useEffect(() => {
    console.log('weeklySubs', weeklySubs);

    if (weeklySubs.length > 0 && weeklySubs[0].value === '') {
      asyncMap();
    }
  }, [weeklySubs]);

  return <Line options={options} data={data} />;
};

export default WeeklySubs;
