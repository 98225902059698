import React, { useEffect, useState } from 'react';
import { Form, Image, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import styles from './TCModal.module.scss';
import logoImage from 'assets/images/logo.png';
import { getTCs, getdashboardState } from 'reducers/dashboardReducer';

const TCModal = ({ rest, isShow, onHide }) => {
  const dispatch = useDispatch();

  const dashboardState = useSelector(getdashboardState);
  const { tcList } = dashboardState;

  const [formData, setFormData] = useState({
    tc: '',
    privacy: '',
  });

  useEffect(() => {
    if (isShow && tcList.length === 0) {
      dispatch(getTCs());
    }
  }, [isShow, tcList]);

  useEffect(() => {
    console.log('tcList', tcList);

    if (tcList && tcList?.data && tcList?.data.length > 0) {
      console.log('uawaa');
      const tcData = tcList?.data.find((d) => d?.attributes?.type === 'terms');
      const policyData = tcList?.data.find((d) => d?.attributes?.type === 'policy');

      setFormData({
        tc: tcData?.attributes?.description,
        privacy: policyData?.attributes?.description,
      });
    }
  }, [tcList]);

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  return (
    <Modal
      {...rest}
      size='lg'
      centered
      show={isShow}
      onHide={onHide}
      className={styles?.TCModal}
      aria-labelledby='website-terms-and-condition'
    >
      <Modal.Header className={styles?.header} closeButton>
        <Modal.Title id='website-terms-and-condition' className={styles?.title}>
          <Image src={logoImage} />
          Terms and Conditions
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles?.body}>
        <Form.Control as='textarea' rows={10} value={formData?.tc} readOnly />

        <h2 className='mt-4 mb-4'>Privacy Policy</h2>

        <Form.Control as='textarea' rows={10} value={formData?.privacy} readOnly />
      </Modal.Body>
    </Modal>
  );
};

export default TCModal;
