import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';

import { selectAuth } from 'reducers/authReducer';
import { setAuthToken } from 'services/setAuthToken';

// Public Routes
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import PageNotFound from 'pages/404';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';

// Private Routes
import Faq from 'pages/Faq';
import MyProfile from 'pages/MyProfile';
import Dashboard from 'pages/Dashboard';
import UpdateProfile from 'pages/UpdateProfile';
import PayoutRequest from 'pages/PayoutRequest';
import ContentSubmission from 'pages/ContentSubmission';
import MakePayoutRequest from 'pages/MakePayoutRequest';
import ViewApprovedContent from 'pages/ViewApprovedContent';
import AddContentSubmission from 'pages/AddContentSubmission';
import ApprovedContentsList from 'pages/ApprovedContentsList';
import EditContentSubmission from 'pages/EditContentSubmission';

const SystemRoutes = () => {
  const loginStoreState = useSelector(selectAuth);
  const { token, isAuthenticated } = loginStoreState;

  setAuthToken(token);

  return isAuthenticated ? (
    // Private Routes
    <Routes>
      <Route path='/faq' element={<Faq />} />
      <Route path='/' element={<Dashboard />} />
      <Route path='/my-profile' element={<MyProfile />} />
      <Route path='/payout-request' element={<PayoutRequest />} />
      <Route path='/my-profile/edit' element={<UpdateProfile />} />
      <Route path='/content-submission' element={<ContentSubmission />} />
      <Route path='/approved-content-list' element={<ApprovedContentsList />} />
      <Route path='/content-submission/:id' element={<EditContentSubmission />} />
      <Route path='/payout-request/make-payout' element={<MakePayoutRequest />} />
      <Route path='/approved-content-list/:id' element={<ViewApprovedContent />} />
      <Route path='/content-submission/add-content' element={<AddContentSubmission />} />

      {/* When Route not found will display this */}
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  ) : (
    // Public Routes
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/password-reset' element={<UpdatePassword />} />
      <Route path='/account-recovery' element={<ForgotPassword />} />

      {/* When Route not found will redirect to login page */}
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default SystemRoutes;
