/* eslint-disable camelcase */
// Import core & vendor packages below
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';

// Components
import FormText from 'components/GlobalUIs/FormText';
import FormUpload from 'components/GlobalUIs/FormUpload';
import { signup, selectAuth } from 'reducers/authReducer';
import FormCheckBox from 'components/GlobalUIs/FormCheckBox';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';
import SuccessModal from './components/SuccessModal';
// import FormSelection from 'components/GlobalUIs/FormSelection';

// Import assets below
import styles from './index.module.scss';
import WebsiteLogo from 'assets/images/logo.png';
import LoginImage from 'assets/images/login Image.png';

const initialState = {
  email: '',
  lastname: '',
  password: '',
  selfieID: [],
  firstname: '',
  mobileNumber: '',
  verificationID: [],
  userType: 'creator',
  confirmPassword: '',
  termsAndCondition: false,
};

// Main component
const SignUp = () => {
  const dispatch = useDispatch();

  const authState = useSelector(selectAuth);
  const { signUpLogs } = authState;

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);

  const handleLogin = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataVal = new FormData();

    formDataVal.append('email', formData?.email);
    formDataVal.append('first_name', formData?.firstname);
    formDataVal.append('password', formData?.password);
    formDataVal.append('password_confirmation', formData?.confirmPassword);
    formDataVal.append('username', formData?.email);
    formDataVal.append('mobile_number', formData?.mobileNumber);
    formDataVal.append('last_name', formData?.lastname);
    formDataVal.append('type', formData?.userType);
    formDataVal.append('verification_id', formData?.verificationID[0]);
    formDataVal.append('selfie_id', formData?.selfieID[0]);

    dispatch(signup(formDataVal));
  };

  const firstnameProps = {
    type: 'text',
    name: 'Firstname',
    noLabel: true,
    setState: setFormData,
    stateValue: formData?.firstname,
    placeholder: 'Firstname',
    required: true,
  };

  const lastnameProps = {
    type: 'text',
    name: 'Lastname',
    noLabel: true,
    setState: setFormData,
    stateValue: formData?.lastname,
    placeholder: 'Lastname',
    required: true,
  };

  const mobileProps = {
    type: 'tel',
    name: 'Mobile Number',
    noLabel: true,
    setState: setFormData,
    stateValue: formData?.mobileNumber,
    placeholder: 'Mobile Number',
    required: true,
  };

  const emailProps = {
    type: 'text',
    name: 'Email',
    noLabel: true,
    setState: setFormData,
    stateValue: formData?.email,
    placeholder: 'Email Address',
    required: true,
  };

  // eslint-disable-next-line multiline-comment-style
  // const userTypeProps = {
  //   noLabel: true,
  //   required: true,
  //   name: 'User Type',
  //   setState: setFormData,
  //   placeholder: 'User Type',
  //   options: ['user', 'creator'],
  //   stateValue: formData?.userType,
  // };

  const passwordProps = {
    noLabel: true,
    type: 'password',
    name: 'Password',
    setState: setFormData,
    placeholder: 'Password',
    stateValue: formData?.password,
    required: true,
  };

  const confirmPasswordProps = {
    noLabel: true,
    type: 'password',
    setState: setFormData,
    name: 'Confirm Password',
    placeholder: 'Confirm Password',
    stateValue: formData?.confirmPassword,
    required: true,
  };

  const submitButtonProps = {
    value: 'Sign Up',
    type: 'submit',
    variant: 'primary',
    className: styles?.submitButton,
    isDisabled: signUpLogs?.isLoading,
  };

  const selfieIdUploadProps = {
    name: 'Selfie ID',
    setState: setFormData,
    text: 'Selfie ID',
    stateValue: formData?.selfieID,
    haveClass: styles?.uploadId,
  };

  const verifyIdUploadProps = {
    name: 'Verification ID',
    setState: setFormData,
    text: 'Verification ID',
    stateValue: formData?.verificationID,
    haveClass: styles?.uploadId,
  };

  const termsAndConditionProps = {
    name: 'termsAndCondition',
    setState: setFormData,
    haveClass: styles?.checkBox,
    stateValue: formData?.termsAndCondition,
    required: true,
    text: (
      <p>
        I agree to the<span> Terms and Conditions</span>
      </p>
    ),
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  renderSubmitCallback(signUpLogs, handleLogin, setShowModal);

  return (
    <Container fluid>
      <div className={styles?.signUpContainer}>
        <Form className={`shadow-sm ${styles?.signUpForm}`} onSubmit={handleSubmit}>
          <Image src={WebsiteLogo} alt='Website Logo' />

          <div className={styles?.loginHeader}>
            <h6>Sign Up</h6>
            <p>Create an account to continue</p>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...firstnameProps} />

            <FormText {...lastnameProps} />

            <FormText {...mobileProps} />

            <FormText {...emailProps} />

            <FormText {...passwordProps} />

            <FormText {...confirmPasswordProps} />

            {/* <FormSelection {...userTypeProps} /> */}
          </div>

          <div className={styles?.marginContainer}>
            <FormUpload {...selfieIdUploadProps} />

            <FormUpload {...verifyIdUploadProps} />

            <ButtonSubmit {...submitButtonProps} />

            <div className={styles?.signup}>
              <FormCheckBox {...termsAndConditionProps} />

              <Link className={styles?.link} to='/'>
                Return to login
              </Link>
            </div>
          </div>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </Container>
  );
};

/* eslint-disable no-unused-vars */
const renderSubmitCallback = (signUpLogs, handleLogin, setShowModal) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = signUpLogs;

    if (isSuccess) {
      setShowModal(true);

      setTimeout(() => {
        handleLogin();
      }, 2000);
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [signUpLogs]);
};

export default SignUp;
