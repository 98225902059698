/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { Form, Image, Table, Button, Popover, FormControl, OverlayTrigger } from 'react-bootstrap';

import { selectAuth } from 'reducers/authReducer';
import { objectCleaner } from 'helpers/objectCleaner';
import { getContentStatus } from 'helpers/getStatus';
import ConditionalRender from 'components/ConditionalRender';
import TablePagination from 'components/GlobalUIs/Pagination';
import { getContentList, getcontentState } from 'reducers/contentReducer';

import styles from './index.module.scss';
import actionIcon from 'assets/images/action.png';
import SampleThumb1 from 'assets/images/thumb1.png';
import SampleThumb2 from 'assets/images/thumb2.png';
import SampleThumb3 from 'assets/images/thumb3.png';
import { ReactComponent as SearchSVG } from 'assets/svg/Search.svg';
import { ReactComponent as LoadingSVG } from 'assets/svg/loadingv2.svg';

const samepleList = [
  {
    id: 1,
    status: 'Approved',
    category: 'Action',
    thumb: SampleThumb1,
    title: 'Video Title 1',
    duration: '10 Minutes',
    earnings: 'Php 10,000.00',
    streamTime: '1000 Minutes',
    description: 'Lorem ipsum dolor sit amet...',
  },
  {
    id: 2,
    status: 'Approved',
    thumb: SampleThumb2,
    category: 'Romance',
    title: 'Video Title 2',
    duration: '10 Minutes',
    earnings: 'Php 10,000.00',
    streamTime: '1000 Minutes',
    description: 'Lorem ipsum dolor sit amet...',
  },
  {
    id: 3,
    status: 'Approved',
    category: 'Rom-Com',
    thumb: SampleThumb3,
    duration: '10 Minutes',
    title: 'Video Title 3',
    earnings: 'Php 10,000.00',
    streamTime: '1000 Minutes',
    description: 'Lorem ipsum dolor sit amet...',
  },
  {
    id: 4,
    status: 'Approved',
    category: 'Comedy',
    thumb: SampleThumb2,
    title: 'Video Title 4',
    duration: '10 Minutes',
    earnings: 'Php 10,000.00',
    streamTime: '1000 Minutes',
    description: 'Lorem ipsum dolor sit amet...',
  },
  {
    id: 5,
    status: 'Approved',
    thumb: SampleThumb1,
    category: 'Thriller',
    title: 'Video Title 5',
    duration: '10 Minutes',
    earnings: 'Php 10,000.00',
    streamTime: '1000 Minutes',
    description: 'Lorem ipsum dolor sit amet...',
  },
];

const Content = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const timerRef = useRef(null);

  const contentState = useSelector(getcontentState);
  const userState = useSelector(selectAuth);

  const { userInfo } = userState;
  const { contentList, contentLogs } = contentState;

  const [params, setParams] = useState({
    q: '',
    id: '',
    page: 1,
    per_page: 5,
    order_by: '',
    filter_status: 'approved',
  });

  const handleView = (data) => {
    console.log('clicked');
    navigate(`/approved-content-list/${data?.id}`);
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;

    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    // Reset timer when user type before 1 second occur
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Run function after 1 second if the timer isn't reset
    timerRef.current = setTimeout(() => {
      const newObj = {
        q: value,
        per_page: 5,
        page: params?.page,
        filter_status: params?.filter_status,
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(
        getContentList({
          parameters: parameters,
          id: params?.id,
        }),
      );
    }, 1000);
  };

  const handlePaginationClick = ({ page }) => {
    const newObj = {
      page: page,
      per_page: 5,
      q: params?.q,
      filter_status: params?.filter_status,
    };

    const { parameters } = objectCleaner(newObj);

    console.log('handlePaginationClick...');

    dispatch(
      getContentList({
        parameters: parameters,
        id: params?.id,
      }),
    );
  };

  setUserInfo(userInfo, params, setParams);

  return (
    <div className={styles?.dashboard}>
      <div className={styles?.approvedList}>
        <div className={styles?.header}>
          <h5>APPROVED CONTENTS LIST</h5>

          <div className={styles?.filters}>
            <Form.Group className={styles?.search}>
              <SearchSVG className={styles?.searchIcon} />

              <FormControl
                name='q'
                value={params?.q}
                onChange={handleSearch}
                placeholder='Search...'
              />
            </Form.Group>
          </div>
        </div>

        <Table responsive className={styles?.UploadTable}>
          <thead>
            <tr>
              <th>THUMBNAIL</th>
              <th>VIDEO TITLE</th>
              <th>DESCRIPTION</th>
              <th>DURATION</th>
              <th>CATEGORY</th>
              <th>STREAM TIME</th>
              <th>EARNINGS</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ConditionalRender
              condition={contentLogs?.isLoading}
              renderIf={
                <tr>
                  <td colSpan={7} className={styles?.loading}>
                    <LoadingSVG />
                  </td>
                </tr>
              }
              renderElse={
                contentList?.data &&
                contentList?.data.length > 0 &&
                contentList?.data.map((data) => {
                  console.log('data', data);

                  return (
                    <tr key={data?.id}>
                      <td>
                        <Image className={styles?.thumnbnail} src={data?.attributes?.thumbnail} />
                      </td>
                      <td>{data?.attributes?.title}</td>
                      <td>{data?.attributes?.description}</td>
                      <td>{data?.attributes?.duration}</td>
                      <td>{data?.attributes?.category}</td>
                      <td>{`${data?.attributes?.total_stream} min${
                        data?.attributes?.total_stream > 1 ? 's' : ''
                      }`}</td>
                      <td>{data?.attributes?.total_earnings}</td>
                      <td>{getContentStatus(data?.attributes?.content_status)}</td>
                      <td>
                        <OverlayTrigger
                          trigger='click'
                          placement='bottom'
                          overlay={
                            <Popover id='TableAction'>
                              <Popover.Body className='d-flex pop-up-cont justify-content-between p-0'>
                                <Button
                                  onClick={() => {
                                    handleView(data);
                                  }}
                                  className='view-data'
                                >
                                  View
                                </Button>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <Button
                            variant='secondary'
                            className='popoverButton bg-transparent p-0 border-0'
                          >
                            <Image src={actionIcon} className={styles?.action} />
                          </Button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  );
                })
              }
            />
          </tbody>
        </Table>

        <TablePagination
          data={contentList}
          setParams={setParams}
          loading={contentLogs?.isLoading}
          handlePaginationClick={handlePaginationClick}
        />
      </div>
    </div>
  );
};

const setUserInfo = (userInfo, params, setParams) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.id) {
      setParams((prev) => {
        return {
          ...prev,
          id: userInfo?.id,
        };
      });

      const newObj = {
        per_page: 5,
        q: params?.q,
        page: params?.page,
        filter_status: params?.filter_status,
      };

      const { parameters } = objectCleaner(newObj);

      dispatch(
        getContentList({
          id: userInfo?.id,
          parameters: parameters,
        }),
      );
    }
  }, [userInfo?.id]);
};

export default Content;
