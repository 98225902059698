/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Form, FormControl, Button } from 'react-bootstrap';

import WeeklySubs from './Graphs/WeeklySubs';
import WeeklyViews from './Graphs/WeeklyViews';
import WatchedTime from './Graphs/WatchedTime';

import styles from './Analytics.module.scss';

const Analytics = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const handleCancel = () => {
    navigate('/my-profile');
  };

  const handleSave = () => {
    // api here the data is inside formData
  };

  return (
    <div className={styles?.analyticsContainer}>
      <h4>Channel and Profile Customization</h4>

      <div className={styles?.formContainer}>
        <WeeklyViews />
      </div>

      <div className={styles?.formContainer}>
        <WatchedTime />
      </div>

      <div className={styles?.formContainer}>
        <WeeklySubs />
      </div>

      <div className={styles?.actionButtons}>
        <Button className={styles?.save} onClick={handleSave}>
          Save
        </Button>
        <Button className={styles?.cancel} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default Analytics;
