// Core & Vendor Packages
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Image, Container } from 'react-bootstrap';

// Components
import FormText from 'components/GlobalUIs/FormText';
import ButtonSubmit from 'components/GlobalUIs/ButtonSubmit';
import { forgotPassword, selectAuth } from 'reducers/authReducer';
import SuccessModal from './components/SuccessModal';

// Assets
import styles from './index.module.scss';
import WebsiteLogo from 'assets/images/logo.png';
import LoginImage from 'assets/images/login Image.png';

const initialState = {
  email: '',
  password: '',
};

// Main Component
const ForgotPassword = () => {
  const dispatch = useDispatch();

  const authState = useSelector(selectAuth);
  const { forgotPassLogs } = authState;

  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      email: formData?.email,
    };

    dispatch(forgotPassword(params));
  };

  const handleLogin = () => {
    navigate('/');
  };

  const emailProps = {
    type: 'email',
    name: 'Email',
    noLabel: true,
    haveClass: '',
    setState: setFormData,
    stateValue: formData?.email,
    placeholder: 'Email Address',
  };

  const submitButtonProps = {
    value: 'Send Link',
    type: 'submit',
    variant: 'primary',
    className: styles?.submitButton,
    isDisabled: forgotPassLogs?.isLoading,
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  renderSubmitCallback(forgotPassLogs, handleLogin, setShowModal);

  return (
    <Container fluid>
      <div className={styles?.forgotContainer}>
        <Form className={`shadow-sm ${styles?.loginForm}`} onSubmit={handleSubmit}>
          <Image src={WebsiteLogo} alt='Website Logo' />

          <div className={styles?.loginHeader}>
            <h6>Forgot Password</h6>
            <p>Enter your Email Address and we will send you a link to reset your Password.</p>
          </div>

          <div className={styles?.formContainer}>
            <FormText {...emailProps} />
          </div>

          <div className={styles?.marginContainer}>
            <ButtonSubmit {...submitButtonProps} />

            <div className={styles?.signup}>
              <Link className={styles?.link} to='/'>
                Return to login
              </Link>
            </div>
          </div>
        </Form>

        <div className={styles.imageContainer}>
          <Image src={LoginImage} />
        </div>

        <SuccessModal show={showModal} onHide={handleHideModal} />
      </div>
    </Container>
  );
};

const renderSubmitCallback = (forgotPassLogs, handleLogin, setShowModal) => {
  useEffect(() => {
    const { error, isFailed, isSuccess } = forgotPassLogs;

    if (isSuccess) {
      setShowModal(true);

      setTimeout(() => {
        handleLogin();
      }, 2000);
    }

    if (isFailed) {
      toast.error(error);
    }
  }, [forgotPassLogs]);
};

export default ForgotPassword;
