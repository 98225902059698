// Core & Vendor Packages
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Navbar, Container } from 'react-bootstrap';

// Components
import { logout } from 'reducers/authReducer';
import Sidebar from 'components/Layouts/Sidebar';
import { selectAuth } from 'reducers/authReducer';

// Helpers
import { getCurrentDateTime } from 'helpers/getCurrentDateTime';

// Assets
import 'components/Styles/UserAccount.scss';
import Websitelogo from 'assets/images/logo.png';
import avatarImg from 'assets/images/avatar.jpg';
import { ReactComponent as LogoutSVG } from 'assets/svg/logout.svg';

// Main Component
const NavBar = () => {
  const dispatch = useDispatch();

  const userState = useSelector(selectAuth);
  const { userInfo } = userState;

  const logOut = () => {
    dispatch(logout());

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  return (
    <Navbar variant='dark' expand={false}>
      <Container fluid>
        <Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${false}`} />

          <Image src={Websitelogo} />
          <span className='welcome'>Welcome, Content Creator</span>
        </Navbar.Brand>

        <div className='center-date'>Today is {getCurrentDateTime()}</div>

        <div className='user-account'>
          <Image alt='account picture' src={userInfo?.attributes?.profile_pic || avatarImg} />
          <p>{userInfo?.attributes?.full_name}</p>
          <LogoutSVG className='logout' onClick={logOut} />
        </div>

        <Sidebar />
      </Container>
    </Navbar>
  );
};

export default NavBar;
