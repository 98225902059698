export const getCurrentDateTime = () => {
  const options = {
    hour12: true,
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const dateString = formatter.format(new Date());
  return dateString;
};
