/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { Row, Col, Container } from 'react-bootstrap';

// Components
import Content from './components/MakePayoutRequest';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Main component
const MakePayoutRequest = () => {
  return (
    <PrivateLayout pageTitle='Make Payout'>
      <Container fluid>
        <Row>
          <Col>
            <Content />
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default MakePayoutRequest;
