/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars

import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Image, Button, Form, Accordion } from 'react-bootstrap';

import VideoJS from 'components/VideoJS';
import { getFAQs, getdashboardState } from 'reducers/dashboardReducer';

import styles from './Content.module.scss';

const Content = ({ userInfo }) => {
  const dispatch = useDispatch();

  const playerRef = useRef(null);

  const dashboardState = useSelector(getdashboardState);
  const { faqList, faqListLogs } = dashboardState;

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  const videoOptions = (data) => {
    return {
      fluid: true,
      controls: true,
      autoplay: true,
      responsive: true,
      aspectRatio: '16:9',
      sources: [
        {
          src: data,
          type: 'application/dash+xml',
        },
      ],
    };
  };

  getFAQsList(dispatch, userInfo);

  console.log('faqList', faqList);

  return (
    <div className={styles?.content}>
      <Row>
        <Col>
          <div className={styles?.filterContainer}>
            <p>Frequently Asked Questions</p>

            {faqList && faqList?.data && faqList?.data.length > 0 && (
              <div className={styles?.faqList}>
                <Accordion>
                  {faqList?.data.map((data, idx) => {
                    const faqsVideoId = data?.relationships?.faqs_video?.data?.id;
                    const video = faqList?.included.find((d) => +d?.id === +faqsVideoId);

                    console.log(
                      'video?.attributes?.video_path_dash',
                      video?.attributes?.video_path_dash,
                    );

                    return (
                      <Accordion.Item
                        className={`shadow-sm ${styles?.accordion}`}
                        key={idx}
                        eventKey={idx}
                      >
                        <Accordion.Header>{data?.attributes?.title}</Accordion.Header>

                        <Accordion.Body>
                          {video?.attributes?.video_path_dash && (
                            <div className='mb-3'>
                              <VideoJS
                                className='dash-video'
                                onReady={handlePlayerReady}
                                options={videoOptions(video?.attributes?.video_path_dash)}
                              />
                            </div>
                          )}

                          <p>{data?.attributes?.description}</p>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const getFAQsList = (dispatch, userInfo) => {
  useEffect(() => {
    if (userInfo?.id) {
      dispatch(getFAQs({}));
    }
  }, [userInfo?.id]);
};

export default Content;
