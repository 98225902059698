/* eslint-disable camelcase */
/* eslint-disable multiline-comment-style */
/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import React, { useState, useMemo, useEffect } from 'react';

import { getCastList } from 'reducers/contentReducer';
import FormSelection from 'components/GlobalUIs/FormSelection';
import CastImageUpload from 'components/GlobalUIs/CastImageUpload';

import styles from './cast.module.scss';
import avatarImage from 'assets/images/avatar.jpg';
import { toast } from 'react-toastify';

const Cast = ({ setFormData, formData }) => {
  const dispatch = useDispatch();

  // const [artist, setArtist] = useState(sampleCast);
  const [artist, setArtist] = useState([]);

  const artistList = useMemo(
    () =>
      artist.map((data) => {
        return {
          ...data,
          value: +data?.id,
          label: data?.cast_name,
          cast_picture: data?.cast_picture,
          cast_role_name: data?.cast_role_name,
        };
      }),
    [artist],
  );

  const handleCastChange = (data, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      const filteredData = data.map((d) => {
        if (d?.value === actionMeta?.option?.value) {
          const newId = uuidv4();

          return {
            ...d,
            id: newId,
            value: newId,
            cast_name: d?.label,
          };
        }

        return d;
      });

      setFormData((prev) => {
        return {
          ...prev,
          cast: filteredData,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          cast: data,
        };
      });
    }
  };

  const handleNewCastData = (data) => (e) => {
    const { value, name } = e.target;

    const newCastData = formData?.cast.map((d) => {
      if (d?.id === data?.id) {
        if (name === 'name') {
          return {
            ...d,
            label: value,
            cast_name: value,
          };
        }

        return {
          ...d,
          [name]: value,
        };
      }

      return d;
    });

    setFormData((prev) => {
      return {
        ...prev,
        cast: newCastData,
      };
    });
  };

  const showNewCast = () => {
    if (!formData?.cast) return;

    const result = formData?.cast?.filter((d) => d?.__isNew__ === true);

    return result.map((data, idx) => {
      return (
        <div key={idx} className={styles?.castSingleList}>
          <CastImageUpload data={data} formData={formData} setFormData={setFormData} />

          <div className={styles?.details}>
            <Form.Control
              name='name'
              type='text'
              value={data?.label}
              placeholder={'Enter Name'}
              className={styles?.formControl}
              onChange={(e) => handleNewCastData(data)(e)}
            />

            <Form.Control
              type='text'
              name='cast_role_name'
              placeholder={'Enter Role'}
              className={styles?.formControl}
              value={data?.cast_role_name || ''}
              onChange={(e) => handleNewCastData(data)(e)}
            />
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    const getCasts = async () => {
      const response = await dispatch(
        getCastList({
          per_page: 1000,
        }),
      );

      if (response?.meta?.requestStatus === 'fulfilled') {
        const newData = response?.payload?.data?.data.map((data) => {
          return {
            ...data?.attributes,
            id: data?.id,
          };
        });

        setArtist(newData);
      } else {
        toast.error(
          'Something went wrong in retrieving cast list. Please try to submit or refresh the page.',
        );
      }
    };

    getCasts();
  }, []);

  useEffect(() => {
    console.log('formData?.cast', formData?.cast);
  }, [formData?.cast]);

  useEffect(() => {
    console.log('artistList', artistList);
  }, [artistList]);

  return (
    <div className={styles?.castContainer}>
      <h4>Cast (Optional)</h4>

      <div className={styles?.castList}>
        <CreatableSelect
          isMulti
          options={artistList}
          value={formData?.cast}
          onChange={handleCastChange}
          formatOptionLabel={(artist) => {
            return (
              <div className={styles?.artistOption}>
                {/* {handleArtistImage(artist)} */}
                {<HandleArtistImage artist={artist} />}

                <p>
                  {artist?.label}
                  <span>{artist?.cast_role_name}</span>
                </p>
              </div>
            );
          }}
        />
      </div>

      <div className={styles?.newCastDetails}>{showNewCast()}</div>
    </div>
  );
};

const HandleArtistImage = ({ artist }) => {
  const handleReturn = () => {
    if (!artist) return;

    if (artist?.cast_picture && typeof artist?.cast_picture === 'string') {
      return <img src={artist?.cast_picture} />;
    }

    if (artist?.cast_picture) {
      const sampleURL = URL.createObjectURL(artist?.cast_picture);

      return <img src={sampleURL} />;
    }
  };

  return handleReturn();
};

export default Cast;
