/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */

import Chart from './Chart';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Button, Image } from 'react-bootstrap';

import { getdashboardState, getTransanctionHistory } from 'reducers/dashboardReducer';

import gcashSample from 'assets/images/gcash.png';
import paymayaSample from 'assets/images/paymaya.png';
import styles from './Content.module.scss';

const Content = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const dashboardState = useSelector(getdashboardState);
  const { transactionHistoryList, payoutData, transactionHistoryLogs, getPayoutList } =
    dashboardState;

  const [transactionHistory, setTransactionHistory] = useState([]);

  const handleMakePayment = () => {
    navigate('/payout-request/make-payout');
  };

  getTransactionHistoryState(transactionHistoryLogs, setTransactionHistory, transactionHistoryList);

  return (
    <div className={styles?.contentContainer}>
      <Row>
        <Col sm={5}>
          <div className={styles?.balanceContainer}>
            <p>
              <span>Withdrawable Balance</span>
              PHP {getPayoutList && getPayoutList[0]?.attributes?.balance}
              {/* {(getPayoutList &&
                getPayoutList[0]?.attributes?.balance
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) ||
                0} */}
            </p>
          </div>

          <Button className={styles?.payoutRequest} onClick={handleMakePayment}>
            Make Payout Request
          </Button>
        </Col>
        <Col>
          <div className={styles?.recentTransaction}>
            <h3>Recent Transactions</h3>

            <div className={styles?.transactionList}>
              <ul>
                {transactionHistory &&
                  transactionHistory.length > 0 &&
                  transactionHistory.map((data, idx) => {
                    return (
                      <li key={idx} className={styles?.list}>
                        <div className={styles?.leftContainer}>
                          <Image src={data?.img} />

                          <p>
                            {data?.paymentChannel} <span>{data?.status}</span>
                          </p>
                        </div>
                        <div className={styles?.rightContainer}>
                          <p>
                            {data?.detail} <span>{data?.date}</span>
                          </p>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Chart />
        </Col>
      </Row>
    </div>
  );
};

const getTransactionHistoryState = (
  transactionHistoryLogs,
  setTransactionHistory,
  transactionHistoryList,
) => {
  useEffect(() => {
    const { isFailed, isSuccess, type } = transactionHistoryLogs;

    if (isFailed) {
      toast.error(
        'Something went wrong in retrieving Transaction History. Please refresh the page',
      );
    }

    if (isSuccess) {
      const newData = transactionHistoryList?.data.map((dd) => {
        return {
          data: dd,
          id: dd?.id,
          detail: dd?.attributes?.amount,
          date: dd?.attributes?.created_at,
          status: dd?.attributes?.payout_status,
          paymentChannel: dd?.attributes?.payment_channel,
          img: getBankImage(dd?.attributes?.payment_channel),
        };
      });

      setTransactionHistory(newData);
    }
  }, [transactionHistoryLogs]);
};

const getBankImage = (type) => {
  switch (type) {
    case 'gcash':
      return gcashSample;
    case 'maya':
      return paymayaSample;
    case 'Gcash':
      return gcashSample;
    case 'Maya':
      return paymayaSample;
    default:
      break;
  }
};

export default Content;
