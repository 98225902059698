/* eslint-disable */
import _ from 'lodash';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  getWeeklySubs,
  updateProfile,
  getWeeklyViews,
  getProfileData,
  getWatchedTime,
  getMonthlyPayout,
} from './thunks/profileThunks';

const initialLog = {
  error: null,
  isFailed: false,
  isLoading: false,
  isSuccess: false,
};

const initialState = {
  updateProfileLogs: initialLog,

  // For Profile Data
  profileData: null,
  getProfileDataLogs: initialLog,
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [getProfileData.fulfilled]: (state, action) => {
      state.profileData = action?.payload?.data;
      state.getProfileDataLogs.isSuccess = true;
      state.getProfileDataLogs.isLoading = false;
    },
    [getProfileData.pending]: (state) => {
      state.getProfileDataLogs.error = '';
      state.getProfileDataLogs.isFailed = false;
      state.getProfileDataLogs.isLoading = true;
      state.getProfileDataLogs.isSuccess = false;
    },
    [getProfileData.rejected]: (state, action) => {
      state.getProfileDataLogs.isFailed = true;
      state.getProfileDataLogs.isLoading = false;
      state.getProfileDataLogs.error = action?.payload?.errors[0]?.detail;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.updateProfileLogs.isSuccess = true;
      state.updateProfileLogs.isLoading = false;
    },
    [updateProfile.pending]: (state) => {
      state.updateProfileLogs.error = '';
      state.updateProfileLogs.isFailed = false;
      state.updateProfileLogs.isLoading = true;
      state.updateProfileLogs.isSuccess = false;
    },
    [updateProfile.rejected]: (state, action) => {
      state.updateProfileLogs.isFailed = true;
      state.updateProfileLogs.isLoading = false;
      state.updateProfileLogs.error = action?.payload?.errors[0]?.detail;
    },
    getWeeklyViews,
  },
});

export const { actions: profileActions, reducer: profileReducer } = slice;

export const { logout, setIsprofileenticated } = profileActions;

/* --------------------------------------------------------------------------------- */

const selectDomain = (state) => state.profile || initialState;

export const selectprofile = createSelector([selectDomain], (profileState) => profileState);

/* --------------------------------------------------------------------------------- */

export {
  updateProfile,
  getWeeklySubs,
  getWeeklyViews,
  getProfileData,
  getWatchedTime,
  getMonthlyPayout,
};
