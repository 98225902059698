/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Image } from 'react-bootstrap';

import styles from './header.module.scss';
import { ReactComponent as CheckSVG } from 'assets/svg/check.svg';
import { ReactComponent as PhoneSVG } from 'assets/svg/phone.svg';
import { ReactComponent as GlobeSVG } from 'assets/svg/globe.svg';
import { ReactComponent as VideoSVG } from 'assets/svg/video-2.svg';
import { ReactComponent as EmailSVG } from 'assets/svg/envelope.svg';

const Header = ({ userInfo, profileData }) => {
  return (
    <div
      className={styles?.header}
      style={{ background: `url('${userInfo?.attributes?.profile_banner}')` }}
    >
      <div className={styles?.innerHeader}>
        <div className={styles?.profile}>
          <div className={styles?.imageContainer}>
            <Image
              className={styles?.avatar}
              src={userInfo?.attributes?.profile_pic}
              alt='avatar'
            />
          </div>

          <div className={styles?.name}>
            <p>
              {userInfo?.attributes?.full_name} <CheckSVG className={styles?.check} />
            </p>

            <span>{userInfo?.attributes?.role}</span>
          </div>
        </div>

        <div className={styles?.details}>
          <p>
            <b>
              <EmailSVG /> EMAIL
            </b>
            <span>{userInfo?.attributes?.email}</span>
          </p>
          <p>
            <b>
              <PhoneSVG /> PHONE
            </b>
            <span>{userInfo?.attributes?.mobile_number}</span>
          </p>
          <p>
            <b>
              <VideoSVG /> TOTAL VIDEOS
            </b>
            <span>{profileData?.relationships?.content?.data.length}</span>
          </p>
          <p className={styles?.long}>
            <b>
              <VideoSVG /> TOTAL VIEW COUNT
            </b>
            <span>{userInfo?.attributes?.total_content_views}</span>
          </p>
          <p>
            <b>
              <GlobeSVG />
              COUNTRY
            </b>
            <span>Philippines</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
