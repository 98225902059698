/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, FormSelect } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import SuccessModal from './Successmodal';
import { checkEmpty } from 'helpers/validator';
import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUIs/FormText';
import SelfieUpload from 'components/GlobalUIs/SelfieUpload';
import FormSelection from 'components/GlobalUIs/FormSelection';
import { submitPayout, getpayoutState } from 'reducers/payoutReducer';
import { getPayout, getdashboardState } from 'reducers/dashboardReducer';

import styles from './index.module.scss';
import { ReactComponent as LoadingSVG } from 'assets/svg/button_loading.svg';

const initialState = {
  amount: '',
  selfieID: [],
  accountName: '',
  accountNumber: '',
  paymentChannel: '',
};

const Payout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector(selectAuth);
  const payoutState = useSelector(getpayoutState);

  const dashboardState = useSelector(getdashboardState);
  const { getPayoutList } = dashboardState;

  const { userInfo } = userState;
  const { submitPayoutLogs } = payoutState;

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const handleCancel = () => {
    navigate('/payout-request');
  };

  const handleSubmit = async () => {
    const withdrawableBalance = parseFloat(
      getPayoutList ? getPayoutList[0]?.attributes?.balance : 0,
    );

    const formAmount = parseFloat(formData?.amount);
    const result = checkEmpty(formData);

    if (result) {
      toast.error('Please fill in the empty fields');
      return;
    }

    if (withdrawableBalance === 0) {
      toast.error('Withdrawable balance is 0');
      return;
    }

    if (formAmount > getPayoutList[0]?.attributes?.balance) {
      toast.error('Amount bigger than current withdrawable balance');
      return;
    }

    const params = new FormData();

    if (formAmount <= 0) {
      toast.error('Invalid Amount.');
      return;
    }

    for (const key in formData) {
      if (key === 'selfieID') {
        params.append('selfie_id', formData[key][0]);
      } else if (key === 'paymentChannel') {
        params.append('payment_channel', formData[key]);
      } else if (key === 'accountName') {
        params.append('account_name', formData[key]);
      } else if (key === 'accountNumber') {
        params.append('account_number', formData[key]);
      } else {
        params.append(key, formData[key]);
      }
    }

    toast.info(
      'Please wait while the selfie is being uploaded. It should only take a few moments.',
    );

    const response = await dispatch(submitPayout({ parameters: params, id: userInfo?.id }));

    if (response?.meta?.requestStatus === 'fulfilled') {
      setShowModal(true);

      setTimeout(() => {
        navigate('/payout-request');
      }, [3000]);
      // eslint-disable-next-line no-unreachable
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
        // eslint-disable-next-line no-unreachable
      } else {
        toast.error('Something went wrong. Please try to submit or refresh the page.');
      }
    }
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const selfieConfigs = {
    name: 'Selfie ID',
    setState: setFormData,
    stateValue: formData?.selfieID,
  };

  const amountConfigs = {
    min: 1,
    type: 'number',
    name: 'Amount',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Amount',
    stateValue: formData?.amount,
    onKeyDown: (evt) =>
      (evt.key === 'e' || evt.key === 'E' || evt.key === '-' || evt.key === '.') &&
      evt.preventDefault(),
  };

  const channelConfigs = {
    name: 'Payment Channel',
    haveClass: 'grey-bg',
    setState: setFormData,
    stateValue: formData?.paymentChannel,
    options: ['Gcash', 'Maya'],
  };

  const accountNameConfigs = {
    type: 'text',
    name: 'Account Name',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Account Name',
    stateValue: formData?.accountName,
  };

  const accountNumberConfigs = {
    type: 'number',
    name: 'Account Number',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Account Number',
    stateValue: formData?.accountNumber,
  };

  const submitConfig = {
    as: 'input',
    readOnly: true,
    value: 'Submit',
    onClick: handleSubmit,
    className: styles?.submit,
    disabled: submitPayoutLogs?.isLoading,
  };

  const cancelConfig = {
    as: 'input',
    readOnly: true,
    value: 'Cancel',
    className: styles?.cancel,
    onClick: handleCancel,
  };

  useEffect(() => {
    dispatch(getPayout());
  }, []);

  return (
    <>
      <div className={styles?.dashboard}>
        <div className={styles?.uploadContent}>
          <div className={styles?.header}>
            <h5>Make a Payout</h5>
          </div>

          <div className={styles?.content}>
            <Row>
              <Col sm={7}>
                <div className={styles?.uploadContainer}>
                  <SelfieUpload {...selfieConfigs} />
                </div>
              </Col>
              <Col>
                <div className={styles?.detailsContainer}>
                  <FormText {...amountConfigs} />

                  <FormSelection {...channelConfigs} />

                  <FormText {...accountNameConfigs} />

                  <FormText {...accountNumberConfigs} />
                  <div className={styles?.actions}>
                    {/* <div className={styles?.groupFamily}>
                      <FormSelection {...familyFriendlyContentConfigs} />
                    </div> */}

                    <div className={styles?.buttons}>
                      <Button {...submitConfig} />
                      <Button {...cancelConfig} />

                      {submitPayoutLogs?.isLoading && <LoadingSVG className={styles?.loading} />}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </>
  );
};

export default Payout;
