/* eslint-disable no-unused-vars */
// Core & Vendor Packages
import { Row, Col, Container } from 'react-bootstrap';

// Components
import Content from './components/ContentSubmission';
import PrivateLayout from 'components/GlobalUIs/PrivateLayout';

// Assets
import styles from './index.module.scss';

// Main component
const ContentSubmission = () => {
  return (
    <PrivateLayout pageTitle='Content Submission'>
      <Container fluid>
        <Row>
          <Col>
            <Content />
          </Col>
        </Row>
      </Container>
    </PrivateLayout>
  );
};

export default ContentSubmission;
