import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';

import styles from './modal.module.scss';

const TransactionModal = (props) => {
  const { onHide, selectedTransaction } = props;

  const openLinkInNewTab = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
  };

  return (
    <Modal
      {...props}
      size='md'
      centered
      className={styles?.successModal}
      aria-labelledby='account-created'
    >
      <Modal.Header className={styles?.header}>
        <Modal.Title id='account-created' className={styles?.title}>
          TRANSACTION DETAILS
        </Modal.Title>
      </Modal.Header>
      {selectedTransaction && (
        <Modal.Body className={styles?.body}>
          <Image src={selectedTransaction.img} className={styles?.icon} />

          <p>
            Amount: <span>{selectedTransaction?.data?.attributes?.amount}</span>
          </p>

          <p>
            Account Name: <span>{selectedTransaction?.data?.attributes?.account_name}</span>
          </p>

          <p>
            Account Number: <span>{selectedTransaction?.data?.attributes?.account_number}</span>
          </p>

          <p>
            Requested At: <span>{selectedTransaction?.data?.attributes?.created_at}</span>
          </p>

          <p>
            Status: <span>{selectedTransaction?.data?.attributes?.payout_status}</span>
          </p>

          <p>
            Is Approved:{' '}
            <span>
              {selectedTransaction?.data?.attributes?.is_approved === 0 ? 'Not yet' : 'Approved'}
            </span>
          </p>

          {selectedTransaction?.data?.attributes?.payout_status === 'approved' && (
            <p className={styles?.proofOfDisbursement}>
              Proof of disbursement:{' '}
              <Image
                className={styles?.image}
                src={selectedTransaction?.data?.attributes?.proof_of_disbursement}
                onClick={() =>
                  openLinkInNewTab(selectedTransaction?.data?.attributes?.proof_of_disbursement)
                }
              />
            </p>
          )}

          {selectedTransaction?.data?.attributes?.payout_status === 'denied' && (
            <p>
              Decline Reason: <span>decline_reason</span>
            </p>
          )}

          <Button className={styles?.close} onClick={onHide}>
            OK
          </Button>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default TransactionModal;
