/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar, Offcanvas, Nav, Form, Button } from 'react-bootstrap';

import { ReactComponent as FaqSVG } from 'assets/svg/faq.svg';
import { ReactComponent as DashboardSVG } from 'assets/svg/Dashboard.svg';
import { ReactComponent as MyProfileSVG } from 'assets/svg/My Profile.svg';
import { ReactComponent as PayoutRequestSVG } from 'assets/svg/Payout Request.svg';
import { ReactComponent as ContentSubmissionSVG } from 'assets/svg/Content Submission.svg';
import { ReactComponent as ApproveContentListSVG } from 'assets/svg/Approve Content List.svg';

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Navbar.Offcanvas
      placement='start'
      id='sidebar-container'
      className='sidebar-container'
      aria-labelledby='sidebar-container'
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id='sidebar-title'>ShortFlix</Offcanvas.Title>
      </Offcanvas.Header>

      <Offcanvas.Body>
        {/* <Form className='d-flex'>
          <Form.Control type='search' placeholder='Search' className='me-2' aria-label='Search' />

          <Button variant='outline-success'>Search</Button>
        </Form> */}

        <Nav className='justify-content-end flex-grow-1'>
          <Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} to={'/'}>
            <DashboardSVG className='link-icon' />
            Dashboard
          </Link>
          <Link
            className={`nav-link ${pathname.includes('content-submission') ? 'active' : ''}`}
            to={'/content-submission'}
          >
            <ContentSubmissionSVG className='link-icon' />
            Content Submission
          </Link>
          <Link
            className={`nav-link ${pathname.includes('approved-content-list') ? 'active' : ''}`}
            to={'/approved-content-list'}
          >
            <ApproveContentListSVG className='link-icon' />
            Approved Contents List
          </Link>
          <Link
            className={`nav-link ${pathname.includes('payout-request') ? 'active' : ''}`}
            to={'/payout-request'}
          >
            <PayoutRequestSVG className='link-icon' />
            Payout Request
          </Link>
          <Link
            className={`nav-link ${pathname.includes('my-profile') ? 'active' : ''}`}
            to={'/my-profile'}
          >
            <MyProfileSVG className='link-icon' />
            My Profile
          </Link>
          <Link className={`nav-link ${pathname.includes('faq') ? 'active' : ''}`} to={'/faq'}>
            <FaqSVG className='link-icon' />
            Shortflix Academy
          </Link>
        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  );
};

export default Sidebar;
