/* eslint-disable */
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateProfile = createAsyncThunk(
  'updateProfile',
  async ({ id, parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `/api/user/${id}/edit`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getWeeklyViews = createAsyncThunk(
  'getWeeklyViews',
  async ({ parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `/api/content/dashboard/week/views`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getWatchedTime = createAsyncThunk(
  'getWatchedTime',
  async ({ parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `/api/content/dashboard/week/watch-time`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getWeeklySubs = createAsyncThunk(
  'getWeeklySubs',
  async ({ parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `/api/content/dashboard/week/subscriber`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getMonthlyPayout = createAsyncThunk(
  'getMonthlyPayout',
  async ({ parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'post',
      data: parameters,
      url: `/api/content/show/payout/statistics`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);

export const getProfileData = createAsyncThunk(
  'getProfileData',
  async ({ parameters }, { rejectWithValue }) => {
    return await axios({
      method: 'get',
      data: parameters,
      url: `api/profile?include[]=content`,
      baseURL: process.env.REACT_APP_API_BASE_URL,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response.data);
      });
  },
);
