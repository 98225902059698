/* eslint-disable camelcase */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable multiline-comment-style */

import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Cast from './Cast';
import SuccessModal from './Successmodal';
import { checkEmpty } from 'helpers/validator';
import { formatTime } from 'helpers/getDuration';
import { selectAuth } from 'reducers/authReducer';
import FormText from 'components/GlobalUIs/FormText';
import FileUploads from 'components/GlobalUIs/FileUploads';
import VideoUpload from 'components/GlobalUIs/VideoUpload';
import FormSelection from 'components/GlobalUIs/FormSelection';
import ThumbnailUpload from 'components/GlobalUIs/ThumbnailUpload';
import { submitContent, getcontentState } from 'reducers/contentReducer';
import { ReactComponent as LoadingSVG } from 'assets/svg/button_loading.svg';

import styles from './index.module.scss';

const initialState = {
  srt: [
    {
      file: null,
      id: uuidv4(),
      language: '',
    },
  ],
  cast: [],
  title: '',
  category: '',
  thumbnail: [],
  description: '',
  audienceRestriction: '',
  // familyFriendlyContent: '',
};

const Content = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector(selectAuth);
  const contentState = useSelector(getcontentState);

  const { userInfo } = userState;
  const { submitContentLogs } = contentState;

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [video, setVideo] = useState({
    video: [],
    trailer: [],
    duration: '',
    trailerDuration: '',
  });

  const handleVideoMeta = (data, name) => {
    const file = data;

    const audio = document.createElement('audio');

    audio.addEventListener('loadedmetadata', () => {
      console.log('audio.duration', audio.duration);

      const result = formatTime(audio.duration);

      if (name === 'video') {
        setVideo((prev) => {
          return {
            ...prev,
            duration: result,
          };
        });
      } else if (name === 'trailer') {
        if (audio.duration > 120) {
          toast.error('Trailer exceeds the maximum time limit (2 Minutes)');

          setVideo((prev) => {
            return {
              ...prev,
              trailer: [],
            };
          });

          return;
        }

        setVideo((prev) => {
          return {
            ...prev,
            trailerDuration: result,
          };
        });
      }
    });

    audio.src = URL.createObjectURL(file);
  };

  const handleCancel = () => {
    navigate('/content-submission');
  };

  const hasDuplicates = (data) => {
    return data.some((obj, index) => {
      return (
        data.filter((innerObj, innerIndex) => {
          return innerObj.label === obj.label && index !== innerIndex;
        }).length > 0
      );
    });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          const base64Image = reader.result;
          resolve(base64Image);
        }
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    const params = new FormData();

    const result = checkEmpty(formData, ['cast', 'srt']); // Second parameter is exclude
    const result2 = checkEmpty(video);
    // const result3 = formData?.srt.some((item) => !item?.file || item?.language === '');

    console.log('result', result);
    console.log('result2', result2);

    if (result || result2) {
      toast.error('Please fill in the empty fields');
      return;
    }

    if (formData?.cast.length > 1) {
      if (hasDuplicates(formData?.cast)) {
        toast.error('Have duplicate cast names');
        return;
      }
    }

    if (formData?.cast.length > 6) {
      toast.error('Can cast only up to six actors/actress');
      return;
    }

    for (const key in formData) {
      if (key === 'cast' || key === 'srt') {
        // Do nothing
      } else if (key === 'thumbnail') {
        params.append(key, formData[key][0]);
      } else if (key === 'audienceRestriction') {
        params.append('audience', formData[key]);
      } else {
        params.append(key, formData[key]);
      }
    }

    for (const key in video) {
      if (key === 'video' || key === 'trailer') {
        if (typeof video[key][0] !== 'string') {
          params.append(key, video[key][0]);
        }
      } else {
        params.append(key, video[key]);
      }
    }

    if (formData?.cast.length > 0) {
      for (let i = 0; i < formData?.cast.length; i++) {
        const img = formData?.cast[i].cast_picture;

        if (img instanceof File) {
          const imgRes = await convertFileToBase64(formData?.cast[i]?.cast_picture);

          const newData = {
            cast_name: formData?.cast[i]?.cast_name,
            cast_role_name: formData?.cast[i]?.cast_role_name,
            cast_picture: imgRes,
          };

          params.append(`cast[${i}]`, JSON.stringify(newData));
        } else {
          const oldData = {
            cast_name: formData?.cast[i]?.cast_name,
            cast_role_name: formData?.cast[i]?.cast_role_name,
            cast_picture: formData?.cast[i]?.cast_picture,
          };

          params.append(`cast[${i}]`, JSON.stringify(oldData));
        }
      }
    }

    if (formData?.srt.length > 0 && formData?.srt[0]?.file !== null) {
      for (let ii = 0; ii < formData?.srt.length; ii++) {
        const srtFile = await convertFileToBase64(formData?.srt[ii].file[0]);

        const srcData = {
          file: srtFile,
          language: formData?.srt[ii].language?.value,
        };

        params.append(`subtitle[${ii}]`, JSON.stringify(srcData));
      }
    }

    console.log('formData', formData);

    // eslint-disable-next-line prefer-const
    for (let [key, value] of params.entries()) {
      console.log(key, value);
    }

    // return;

    toast.info('Please wait while the video is being uploaded. It should only take a few moments.');

    const response = await dispatch(submitContent({ parameters: params, id: userInfo?.id }));

    if (response?.meta?.requestStatus === 'fulfilled') {
      setShowModal(true);

      setTimeout(() => {
        navigate('/content-submission');
      }, [3000]);
    } else {
      if (response?.payload?.errors) {
        const { errors } = response.payload;

        errors.forEach((data) => {
          toast.error(data?.detail);
        });
      } else {
        if (response?.payload?.errors) {
          const { errors } = response.payload;

          errors.forEach((data) => {
            toast.error(data?.detail);
          });
        } else {
          toast.error('Something went wrong. Please try to submit or refresh the page.');
        }
      }
    }
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const videoUploadConfigs = {
    name: 'video',
    preload: 'none',
    setState: setVideo,
    stateValue: video?.video,
    videoMeta: handleVideoMeta,
  };

  const trailerUploadConfigs = {
    name: 'trailer',
    preload: 'none',
    setState: setVideo,
    stateValue: video?.trailer,
    videoMeta: handleVideoMeta,
  };

  const thumbnailConfigs = {
    name: 'thumbnail',
    setState: setFormData,
    stateValue: formData?.thumbnail,
  };

  const fileConfigs = {
    name: 'srt',
    label: 'SRT',
    accept: '.srt',
    setState: setFormData,
    stateValue: formData?.srt,
  };

  const titleConfigs = {
    type: 'text',
    name: 'Title',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Title',
    stateValue: formData?.title,
  };

  const descriptionConfigs = {
    rows: '8',
    as: 'textarea',
    type: 'textarea',
    name: 'Description',
    haveClass: 'grey-bg',
    setState: setFormData,
    placeholder: 'Enter Description',
    stateValue: formData?.description,
  };

  const durationConfigs = {
    type: 'text',
    readOnly: true,
    name: 'Duration',
    haveClass: 'grey-bg',
    setState: setVideo,
    placeholder: 'Enter Duration',
    stateValue: video?.duration,
  };

  const categoryConfigs = {
    name: 'Category',
    haveClass: 'grey-bg',
    setState: setFormData,
    stateValue: formData?.category,
    options: [
      'Action',
      'Horror',
      'Comedy',
      'Drama',
      'Sci-Fi',
      'Romance',
      'Documentary',
      'Anime',
      'Cartoons',
      'Crime',
      'Mystery',
      'Vlog',
    ],
  };

  const audienceRestrictionConfigs = {
    haveClass: 'grey-bg',
    setState: setFormData,
    name: 'Audience Restriction',
    options: [
      'All',
      'General audience',
      'Mature',
      'Parental guidance',
      'Restricted',
      'No one under 17',
    ],
    stateValue: formData?.audienceRestriction,
  };

  // const familyFriendlyContentConfigs = {
  //   haveClass: 'grey-bg',
  //   setState: setFormData,
  //   options: ['Yes', 'No'],
  //   name: 'Family Friendly Content',
  //   stateValue: formData?.familyFriendlyContent,
  // };

  const submitConfig = {
    as: 'input',
    readOnly: true,
    value: 'Submit',
    onClick: handleSubmit,
    className: styles?.submit,
    disabled: submitContentLogs?.isLoading,
  };

  const cancelConfig = {
    as: 'input',
    readOnly: true,
    value: 'Cancel',
    className: styles?.cancel,
    onClick: handleCancel,
  };

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  useEffect(() => {
    console.log('video', video);
  }, [video]);

  return (
    <>
      <div className={styles?.dashboard}>
        <div className={styles?.uploadContent}>
          <div className={styles?.header}>
            <h5>UPLOAD CONTENT</h5>
          </div>

          <div className={styles?.content}>
            <Row>
              <Col sm={7}>
                <div className={styles?.uploadContainer}>
                  <div>
                    <label className={styles?.label}>Video</label>
                    <VideoUpload {...videoUploadConfigs} />
                  </div>

                  <div>
                    <label className={styles?.label}>Trailer</label>
                    <VideoUpload {...trailerUploadConfigs} />
                  </div>

                  <div>
                    <label className={styles?.label}>Thumbnail</label>
                    <ThumbnailUpload {...thumbnailConfigs} />
                  </div>

                  <div>
                    <label className={styles?.label}>Subtitle</label>
                    <FileUploads {...fileConfigs} />
                  </div>
                </div>
              </Col>
              <Col>
                <div className={styles?.detailsContainer}>
                  <FormText {...titleConfigs} />

                  <FormText {...descriptionConfigs} />

                  <Cast formData={formData} setFormData={setFormData} />

                  <div className={styles?.settings}>
                    <FormText {...durationConfigs} />

                    <FormSelection {...categoryConfigs} />

                    <FormSelection {...audienceRestrictionConfigs} />
                  </div>

                  <div className={styles?.actions}>
                    {/* <div className={styles?.groupFamily}>
                      <FormSelection {...familyFriendlyContentConfigs} />
                    </div> */}

                    <div className={styles?.buttons}>
                      <Button {...submitConfig} />
                      <Button {...cancelConfig} />

                      {submitContentLogs?.isLoading && <LoadingSVG className={styles?.loading} />}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <SuccessModal show={showModal} onHide={handleHideModal} />
    </>
  );
};

export default Content;
