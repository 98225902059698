/* eslint-disable prefer-const */

export const formatTime = (seconds) => {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  let remainingSeconds = Math.floor(seconds - hours * 3600 - minutes * 60);

  let result = '';
  if (hours > 0) {
    result += `${parseInt(hours)} Hour${hours > 1 ? 's' : ''}, `;
  }
  if (minutes > 0) {
    result += `${parseInt(minutes)} Minute${minutes > 1 ? 's' : ''}, `;
  }
  if (remainingSeconds > 0) {
    result += `${parseInt(remainingSeconds)} Second${remainingSeconds > 1 ? 's' : ''}`;
  }

  return result;
};
